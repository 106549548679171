<template>
  <b-form-group label-cols="4">
    <template v-slot:label>
      {{ label }}
      <b-icon-exclamation-circle
        variant="sgs-red"
        v-if="required"
        v-b-tooltip.hover.top="'Required field'"
      />
      <b-icon-blank v-if="required" font-scale="0.25" />
      <b-icon-question-circle
        variant="info"
        v-if="`${help}` != 'undefined'"
        :title="`${help_title}`"
        v-b-popover.hover.bottomleft.html="`${help}`"
      />
    </template>
    <b-input-group>
      <template v-slot:prepend>
        <b-input-group-text>{{ min }}</b-input-group-text>
      </template>
      <template v-slot:append>
        <b-input-group-text>{{ max }}{{ unit }}</b-input-group-text>
      </template>
      <b-form-input
        :id="`${this.id}`"
        v-model="v_model"
        type="range"
        number
        :required="required"
        :min="min"
        :max="max"
        placeholder="Enter amount"
        @input="updateInput"
        v-b-tooltip.hover.focus.top="`${this.value}${this.unit}`"
      />
    </b-input-group>
  </b-form-group>
</template>

<script>
export default {
  name: "InputRange",
  props: {
    id: String,
    label: String,
    value: [Number, String],
    min: Number,
    max: Number,
    unit: { type: String, default: "" },
    help_title: { type: String, default: "" },
    help: String,
    required: { type: Boolean, default: true },
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      v_model: this.value,
    };
  },
  methods: {
    updateInput() {
      this.$emit("change", this.v_model);
    },
  },
  watch: {
    value() {
      this.v_model = this.value;
    },
  },
};
</script>
