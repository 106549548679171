<template>
  <div>
    <PageTitle
      variant="sgs-red"
      title="Create"
      text="Here's a pre-filled form based on the scenario that you would like to copy. <br/> You can make any changes before submitting this form."
    ></PageTitle>
    <b-overlay :show="loading" rounded="sm" no-wrap />
    <b-container v-if="loaded">
      <OpportunityScenarioForm
        :data="form"
        @onSubmit="onSubmit($event)"
        @submitDisqualified="submitDisqualified($event)"
      />
    </b-container>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import OpportunityScenarioForm from "@/components/OpportunityScenarioForm.vue";

export default {
  name: "CloneOpportunityScenario",
  components: {
    OpportunityScenarioForm,
    PageTitle,
  },
  data() {
    return {
      loading: true,
      loaded: false,
      form: {},
      companies: [],
    };
  },
  created() {
    this.$store
      .dispatch("getScenario", {
        data: this.$route.params.clone,
      })
      .then((response) => {
        this.form = response.data.scenario;
        this.form.scenario_name =
          "Copy of " + response.data.scenario.scenario_name;
        this.loaded = true;
        this.loading = false;
      })
      .catch((error) => {
        this.serverError = error.response.data;
      });
  },
  methods: {
    submitDisqualified(event) {
      this.loading = true;
      this.$store
        .dispatch("createScenarioDisqualified", {
          id: this.$route.params.id,
          data: event,
        })
        .then((response) => {
          this.loading = false;
          console.log(response);
          this.$router.push({
            name: "view_opportunity",
            params: {
              id: this.$route.params.id,
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          alert(error.response.data);
          this.serverError = error.response.data;
        });
    },
    onSubmit(event) {
      this.loading = true;
      this.$store
        .dispatch("createScenario", {
          id: this.$route.params.id,
          data: event,
        })
        .then((response) => {
          this.loading = false;
          this.$router.push({
            name: "view_assessment",
            params: {
              id: response.data.public_id,
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          alert(error.response.data);
          this.serverError = error.response.data;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
