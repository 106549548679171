<template>
  <div>
    <b-card no-body>
      <template v-slot:header>
        <b-card-title class="d-flex justify-content-between align-items-center">
          {{ form.opportunity_name }}
          <b-button
            id="edit_button"
            v-if="editable"
            size="sm"
            variant="sgs-yellow"
            sm
            style="min-width: 65px"
            :href="`/${object}/${form.public_id}/edit`"
          >
            Edit
            <b-icon-pencil-square />
          </b-button>
        </b-card-title>
        <b-card-sub-title
          class="mb-2 d-flex justify-content-between align-items-center"
        >
          <span>
            <b-badge
              variant="sgs-green"
              style="position: relative; top: -0.15em"
            >
              {{ form.investment_type }}
            </b-badge>
            {{ form.opportunity_code | code }}
          </span>
          <b-badge variant="primary">{{ form.stage }} Stage</b-badge>
        </b-card-sub-title>
        <b-card-sub-title
          v-if="object == 'scenario'"
          class="mb-2 d-flex justify-content-between align-items-center"
        >
          Scenario: {{ form.scenario }}
          <b-badge v-if="form.preferred != null" variant="sgs-green">
            {{ form.preferred }}
          </b-badge>
        </b-card-sub-title>
        <b-card-sub-title
          v-if="object == 'opportunity'"
          class="mb-2 d-flex justify-content-between align-items-right"
        >
          <b-icon-blank />
          <b-badge v-if="form.preferred != null" variant="sgs-green">
            {{ form.preferred }}
          </b-badge>
        </b-card-sub-title>
      </template>
      <b-card-body>
        <b-card-text>{{ form.description }}</b-card-text>
        <div v-if="object == 'scenario'">
          <b-badge
            :variant="
              `${
                form.footloose == 'Yes'
                  ? 'scale-yellow'
                  : form.footloose == 'No'
                  ? 'scale-green'
                  : 'secondary'
              }`
            "
          >
            Footloose? {{ form.footloose }}
          </b-badge>
          <b-icon-blank width=".5em" />
          <b-badge
            :variant="`${form.disqualified ? 'scale-red' : 'scale-green'}`"
          >
            Disqualify? {{ form.disqualified | true_or_false }}
          </b-badge>
          <b-icon-blank width=".5em" />
          <b-badge v-if="form.risk == 'Low'" variant="scale-green">
            {{ form.risk }} Risk
          </b-badge>
          <b-badge v-else-if="form.risk == 'Medium/Low'" variant="scale-lime">
            {{ form.risk }} Risk
          </b-badge>
          <b-badge v-else-if="form.risk == 'Medium'" variant="scale-yellow">
            {{ form.risk }} Risk
          </b-badge>
          <b-badge v-else-if="form.risk == 'Medium/High'" variant="scale-pink">
            {{ form.risk }} Risk
          </b-badge>
          <b-badge v-else-if="form.risk == 'High'" variant="scale-red">
            {{ form.risk }} Risk
          </b-badge>
          <b-badge v-else variant="secondary"> {{ form.risk }} Risk </b-badge>
        </div>
      </b-card-body>

      <b-list-group flush>
        <b-list-group-item>
          <b>Assigned to: </b>
          {{ form.opportunity_owner }} ({{ form.team }})
        </b-list-group-item>
        <b-list-group-item>
          <b>Type: </b>
          {{ form.opportunity_type }}
        </b-list-group-item>
        <b-list-group-item>
          <b>Company: </b>
          <b-link :href="form.website">{{ form.company }} </b-link>
          ({{ form.country }})
        </b-list-group-item>
        <b-list-group-item>
          <b>Victorian Address: </b>
          {{ form.address | text }}
        </b-list-group-item>
        <b-list-group-item>
          <b>Contact: </b>
          {{ form.contact_name }}
          <span
            v-if="form.contact_position != '' && form.contact_position != null"
          >
            ({{ form.contact_position | text }})
          </span>
          <br />
          {{ form.contact_phone }} /
          {{ form.contact_email }}
        </b-list-group-item>
        <b-list-group-item v-if="object == 'scenario'">
          <b>Sector:</b>
          {{ form.sector }}
        </b-list-group-item>
        <b-list-group-item
          v-if="
            object == 'scenario' &&
              form.capability != '{}' &&
              form.capability != '' &&
              form.capability != null
          "
        >
          <b>Contribution to capability: </b>
          <b-badge
            variant="scale-green"
            style="margin-right: 0.5em"
            v-for="capability_item in form.capability"
            :key="capability_item"
          >
            {{ capability_item }}
          </b-badge>
          <ul>
            <li>{{ form.capability_text | desc }}</li>
          </ul>
        </b-list-group-item>
        <b-list-group-item v-if="object == 'scenario'">
          <b>Brand: </b>
          <b-badge variant="scale-green" style="margin-right: 0.5em">
            {{ form.brand_recognition }}
          </b-badge>
          <b-badge variant="scale-green" style="margin-right: 0.5em">
            {{ form.global_market_share | global }}
          </b-badge>
          <ul>
            <li>{{ form.brand_text | brand_filter }}</li>
            <li>{{ form.global_market_share_text | market_filter }}</li>
          </ul>
        </b-list-group-item>
        <!-- <b-list-group-item
          v-if="object == 'scenario' && form.social_benefits != null"
        >
          <b>Social benefits: </b> {{ form.social_benefits }}
        </b-list-group-item> -->
        <b-list-group-item v-if="object == 'scenario'">
          <b>Other benefits: </b>
          {{ form.environment_benefits | other_benefits }}
        </b-list-group-item>
        <b-list-group-item v-if="object == 'scenario'">
          <b>Innovation: </b>
          {{ form.rd_engagement }}
          <ul
            v-if="
              form.rd_engagement ==
                'Operations include novel and/or other innovative activities'
            "
          >
            <li>{{ form.unique_level_text | desc }}</li>
            <li>{{ form.productivity_text | desc }}</li>
          </ul>
        </b-list-group-item>
      </b-list-group>

      <b-card-footer></b-card-footer>
    </b-card>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
var numeral = require("numeral");

export default {
  name: "SummaryCard",
  props: ["form", "editable", "object"],
  methods: {},
  filters: {
    code(value) {
      return value.toUpperCase();
    },
    true_or_false(value) {
      return value ? "Yes" : "No";
    },
    score(value) {
      return numeral(value).format("0,0.[0]");
    },
    number(value) {
      return numeral(value).format("0,0");
    },
    currency(value) {
      return numeral(value).format("$0,0");
    },
    global(value) {
      return value == "None of the above" ? "Not in global top 50" : value;
    },
    other_benefits(value) {
      return [null, "", " "].includes(value) ? "None described" : value;
    },
    text(value) {
      return [null, "", " "].includes(value) ? " - " : value;
    },
    desc(value) {
      return [null, "", " "].includes(value)
        ? "No supporting information provided"
        : value;
    },
    brand_filter(value) {
      return [null, "", " "].includes(value)
        ? "No information supporting brand recognition"
        : value;
    },
    market_filter(value) {
      return [null, "", " "].includes(value)
        ? "No information supporting global market position"
        : value;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@media print {
  .list-group-item,
  .card-body {
    padding-top: 0.15em;
    padding-bottom: 0.15em;
  }
  .badge {
    border-style: none;
  }
  ul {
    margin-bottom: 0;
  }
  li,
  p {
    font-size: 0.85em;
  }
  .card-footer {
    padding: 0em;
  }
  #edit_button {
    display: none;
  }
}

.alert {
  padding: 0.5em 1em;
  font-size: 8pt;
  margin-bottom: 0.1em;
}
</style>
