<template>
  <div>
    <PageTitle
      variant="sgs-green"
      title="Feedback"
      text="Here's the opportunity that you would like to give feedback on. <br/> Submit this form to save your feedback."
    ></PageTitle>
    <b-overlay :show="loading" rounded="sm" no-wrap />
    <b-container v-if="loaded">
      <b-row>
        <b-col cols="5">
          <SummaryCard :editable="true" :form="form" object="opportunity" />
        </b-col>
        <b-col>
          <h3>Investor Survey</h3>
          <FeedbackForm @onSubmit="feedbackOpportunity($event)" :data="form" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import FeedbackForm from "@/components/FeedbackForm.vue";
import SummaryCard from "@/components/SummaryCard.vue";

export default {
  name: "OpportunityFeedback",
  components: {
    FeedbackForm,
    PageTitle,
    SummaryCard,
  },
  data() {
    return {
      loading: true,
      loaded: false,
      form: {
        team: null,
        opportunity_name: "",
        opportunity_code: "",
        opportunity_owner: "",
        type: null,
        company: "",
        country: null,
        opportunity_type: null,
        description: "",
        stage: null,
      },
    };
  },
  methods: {
    feedbackOpportunity(event) {
      this.loading = true;
      // event.preventDefault();
      this.$store
        .dispatch("feedbackOpportunity", {
          data: event,
          id: this.$route.params.id,
        })
        .then((response) => {
          this.loading = false;
          this.$router.push({
            name: "view_opportunity",
            params: {
              id: response.data.public_id,
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.response.data);
        });
    },
  },
  created() {
    this.$store
      .dispatch("getOpportunity", {
        data: this.$route.params.id,
      })
      .then((response) => {
        this.form = response.data.opportunity;
        this.loaded = true;
        this.loading = false;
      })
      .catch((error) => {
        this.serverError = error.response.data;
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
