<template>
  <div>
    <b-card no-body>
      <template v-slot:header>
        <b-card-title>
          <b-row>
            <b-col>Score Card</b-col>
            <b-col class="text-right">
              <b-icon-question-circle
                variant="info"
                title="Assessment Scores"
                v-b-popover.hover.bottomleft.html="
                  `
                  The opportunity has been assessed across five dimensions: 
                  <ul>
                    <li>Alignment of <b>sector, location and capabilities</b> with the investment objectives of Invest Victoria</li>
                    <li><b>Innovation</b> in the form of R&D activity, development of new products, or patent generation</li>
                    <li><b>Brand</b> impact on Victoria with respect to attracting global firms, headquarter offices, and innovative activities</li>
                    <li><b>Economic impact</b> on gross state product (GSP) uplift, additional jobs, and increased taxation revenue</li>
                    <li><b>Export impact</b> in terms of both aggregate value and diversity of exports</li>
                  </ul>
                   `
                "
              />
            </b-col>
          </b-row>
        </b-card-title>
        <b-card-sub-title>Range (0-5)</b-card-sub-title>
      </template>
      <b-card-body>
        <b-row>
          <b-col
            style="padding: 0em 0.3em"
            v-for="value in items"
            :key="value.measure"
          >
            <SummaryMeasureItem
              :measure="value.measure"
              :value="value.score"
              type="score"
            />
          </b-col>
        </b-row>
        <hr />
        <RadarChart :chart_data="items" :chart_benchmarks="benchmarks"/>
        <b-row>
          <b-col
            title="Strategic Impact Score"
            v-b-popover.hover.top="
              `This is the weighted score of the scenario across the five dimensions above.`
            "
          >
            <h2 class="text-center">
              <b-badge variant="info" pill>
                {{ aggregate.score | score }} / 5
              </b-badge>
            </h2>
            <b-card-sub-title class="text-center">{{
              aggregate.measure
            }}</b-card-sub-title>
          </b-col>
          <b-col
            title="Data Quality Score"
            v-b-popover.hover.top="
              `This is the weighted score of the input data quality across the five dimensions above.`
            "
          >
            <h2 class="text-center">
              <b-badge variant="info" pill>
                {{ data_quality.score | score }} / 5
              </b-badge>
            </h2>
            <b-card-sub-title class="text-center">{{
              data_quality.measure
            }}</b-card-sub-title>
          </b-col>
        </b-row>
      </b-card-body>
      <b-card-footer></b-card-footer>
    </b-card>
  </div>
</template>

<script>
import SummaryMeasureItem from "@/components/SummaryMeasureItem.vue";
import RadarChart from "@/components/charts/RadarChart.vue";
import Vue from "vue";
var numeral = require("numeral");

Vue.filter("score", function (value) {
  return numeral(value).format("0,0.[0]"); // displaying other groupings/separators is possible, look at the docs
});

export default {
  name: "ScoreCard",
  components: {
    SummaryMeasureItem,
    RadarChart,
  },
  props: ["items", "aggregate", "data_quality", "benchmarks"],
};
</script>

<style scoped>
@media print {
  .badge {
    border-style: none;
  }
  .card-footer {
    padding: 0em;
  }
}
</style>
