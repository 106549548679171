<template>
  <b-form-group label-cols="4">
    <template v-slot:label>
      {{ label }}
      <b-icon-exclamation-circle
        variant="sgs-red"
        v-if="!multiple && required"
        v-b-tooltip.hover.top="'Required field'"
      />
      <b-icon-blank v-if="!multiple" font-scale="0.25" />
      <b-icon-question-circle
        variant="info"
        v-if="`${help}` != 'undefined'"
        :title="`${help_title}`"
        v-b-popover.hover.bottomleft.html="`${help}`"
      />
    </template>
    <b-input-group>
      <b-form-select
        :form="form"
        :id="`${this.id}`"
        v-model="v_model"
        :value-field="value_field"
        :text-field="text_field"
        :options="options"
        :required="!multiple && required"
        :multiple="multiple"
        :select-size="select_size"
        @input="updateInput"
        @change="change"
      >
        <template v-slot:first>
          <b-form-select-option :value="null" :disabled="!required" v-if="!multiple">{{
            placeholder
          }}</b-form-select-option>
        </template>
      </b-form-select>
    </b-input-group>
    <slot></slot>
  </b-form-group>
</template>

<script>
export default {
  name: "InputSelect",
  props: {
    id: String,
    label: String,
    value: { type: [String, Number, Array] },
    options: { type: [Object, Array] },
    form: String,
    value_field: { type: String, default: "value" },
    text_field: { type: String, default: "text" },
    placeholder: { type: String, default: "Select from dropdown" },
    required: { type: Boolean, default: true },
    help_title: { type: String, default: "" },
    help: String,
    multiple: { type: Boolean, default: false },
    select_size: Number,
    change: {
      type: Function,
      default: () => {
        return null;
      },
    },
    // required: { type: Boolean, default: true },
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      v_model: this.value,
    };
  },
  methods: {
    updateInput() {
      this.$emit("change", this.v_model);
    },
  },
  watch: {
    value() {
      this.v_model = this.value;
    },
  },
  mounted() {},
};
</script>
