<script>
//import numeral from 'numeral';
import { Bubble } from "vue-chartjs";
import ChartDataLabels from "chartjs-plugin-datalabels";

var numeral = require("numeral");

// var score = function (value) {
//   return numeral(value).format("0.[0]");
// };
// var currency = function (value) {
//   return numeral(value).format("$0,0.[00]");
// };

export default {
  extends: Bubble,
  components: {
    ChartDataLabels,
  },
  props: [
    "chart_data", "selected_data", 
    "x_axis", "y_axis",
    "trendline"
  ],
  data() {
    return {
      chartData: {
        datasets: [
          {
            borderWidth: 2,
            radius: 2,
            borderColor: "rgb(149, 144, 226)",
            backgroundColor: "rgb(149, 144, 226)",
            data: [],
            // label: "All other projects",
            clip: { top: 50 },
          },
          {
            borderWidth: 2,
            radius: 5,
            borderColor: "rgb(242, 138, 6)",
            backgroundColor: "rgb(242, 138, 6)",
            data: [],
            // label: "Selected project",
            clip: { top: 50 },
          },
          // trend line
          {
            type: "line",
            borderColor: "rgba(0, 0, 0, 0.5)",
            fill: false,
            borderWidth: 1,
            pointRadius: 0,
            data: [
            ],
            datalabels: {
              labels: {
                title: null,
              },
            },
          },
          // {
          //   type: "line",
          //   borderColor: "rgba(0, 0, 0, 0.5)",
          //   borderWidth: 1,
          //   fill: false,
          //   pointRadius: 0,
          //   data: [
          //     {
          //       x: 1,
          //       y: 3,
          //     },
          //     {
          //       x: 5,
          //       y: 3,
          //     },
          //   ],
          //   datalabels: {
          //     labels: {
          //       title: null,
          //     },
          //   },
          // },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              display: true,
              position: "left",
              scaleLabel: {
                display: true,
                labelString: this.y_axis.label,
              },
              filter: function (tooltipItem) {
                  return tooltipItem.datasetIndex < 2;
              },
              ticks: {
                callback: function (value) {
                  return numeral(value).format(this.y_axis.format + "a");
                },
                beginAtZero: true,
                maxTicksLimit: 10,
              },
            },
          ],
          xAxes: [
            {
              filter: function (tooltipItem) {
                  return tooltipItem.datasetIndex < 2;
              },
              display: true,
              position: { y: 3 },
              ticks: this.x_axis.field == "strategic_impact_score" ? {
                beginAtZero: true,
                max: 5,
                min: 1,
                stepSize: 0.5,
              } : {
                callback: function (value) {
                  return numeral(value).format(this.x_axis.format + "a");
                },
                beginAtZero: true,
                maxTicksLimit: 10,
              },
              scaleLabel: {
                display: true,
                labelString: this.x_axis.label,
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            clamp: "true",
            display: "auto",
            anchor: function () {
              // anchor: function (context) {
              // var value = context.dataset.data[context.dataIndex];
              return "end";
              // return value.v < 50 ? "end" : "center";
            },
            // align: "-58",
            align: function (context) {
              // console.log(context.dataset.data[context.dataIndex]);
              var value = context.dataset.data[context.dataIndex];
              return value.y < 10000 ? "-58" : "58";
            },
            // color: function (context) {
            //   var value = context.dataset.data[context.dataIndex];
            //   return value.v < 50 ? context.dataset.backgroundColor : "white";
            // },
            font: {
              weight: "200",
              size: 8,
            },
            formatter: function (value) {
              // console.log(value);
              try {
                return [value.name.split(" ", 3)];
              } catch (e) {
                return null;
              }
            },
            offset: 10,
            padding: 0,
          },
        },
        tooltips: {
          custom: function (tooltip) {
            if (!tooltip) return;
            //disable displaying colour box
            tooltip.displayColors = false;
          },
          filter: function (tooltipItem) {
              return tooltipItem.datasetIndex < 2;
          },
          callbacks: {
            // title: function (tooltipItem, data) {
            //   return [
            //     data.datasets[tooltipItem[0].datasetIndex].data[
            //       tooltipItem[0].index
            //     ].name,
            //   ];
            // },
            label: function (tooltipItem, data) {
              let item = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
              return [
                item.name,
                item.x_axis_label + ": " + 
                numeral(tooltipItem.xLabel).format(item.x_axis_format),
                item.y_axis_label + ": " + 
                numeral(tooltipItem.yLabel).format(item.y_axis_format),
                ""
              ];
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      var all_other_items = [];
      var x_axis = this.x_axis.field
      var y_axis = this.y_axis.field
      var x_axis_label = this.x_axis.label
      var y_axis_label = this.y_axis.label
      var x_axis_format = this.x_axis.format
      var y_axis_format = this.y_axis.format
      this.chart_data.forEach(function (item) {
        var x = {
          x: item[x_axis] != null ? item[x_axis] : 0,
          y: item[y_axis] != null ? item[y_axis] : 0,
          x_axis_label: x_axis_label,
          y_axis_label: y_axis_label,
          x_axis_format: x_axis_format,
          y_axis_format: y_axis_format,
          name: item.opportunity_scenario,
          scenario: item.scenario,
          // data_quality: score(item.score_data_quality),
        };
        all_other_items.push(x);
      });
      this.chartData.datasets[0].data = all_other_items;
      
      var selected_item = [];
      this.selected_data.forEach(function (item) {
        var x = {
          x: item[x_axis],
          y: item[y_axis],
          x_axis_label: x_axis_label,
          y_axis_label: y_axis_label,
          name: item.opportunity_scenario,
          scenario: item.scenario,
          // data_quality: score(item.score_data_quality),
        };
        selected_item.push(x);
      });
      this.chartData.datasets[1].data = selected_item;
      
      // update labels
      this.options.scales.yAxes[0].scaleLabel.labelString = this.y_axis.label
      this.options.scales.xAxes[0].scaleLabel.labelString = this.x_axis.label
      if (this.x_axis.field == "strategic_impact_score") {
        this.options.scales.xAxes[0].ticks = {
          beginAtZero: true,
          max: 5,
          min: 1,
          stepSize: 0.5,
        } 
      } else {
        this.options.scales.xAxes[0].ticks = {
          callback: function (value) {
            return numeral(value).format(x_axis_format + "a");
          },
          min: 0,
          beginAtZero: true,
          maxTicksLimit: 10,
        }
      }
      if (this.y_axis.field == "strategic_impact_score") {
        this.options.scales.yAxes[0].ticks = {
          beginAtZero: true,
          max: 5,
          min: 1,
          stepSize: 0.5,
        } 
      } else {
        this.options.scales.yAxes[0].ticks = {
          callback: function (value) {
            return numeral(value).format(y_axis_format + "a");
          },
          min: 0,
          beginAtZero: true,
          maxTicksLimit: 10,
        }
      }

      this.chartData.datasets[2].data = []
      if (this.trendline == true) {
        // calculate trendline
        if (all_other_items.length > 0) {
          var datasets = this.chartData.datasets[0].data.concat(this.chartData.datasets[1].data)
          this.chartData.datasets[2].data = this.calculateTrendlineCoordinates(datasets)
        }
      }

      this.renderChart(this.chartData, this.options);
    },
    calculateTrendlineCoordinates(data) {
      const n = data.length;

      let sumX = 0;
      let sumY = 0;
      let sumXY = 0;
      let sumX2 = 0;

      for (let i = 0; i < n; i++) {
        const point = data[i];
        const x = parseFloat(point.x);
        const y = parseFloat(point.y);

        if (typeof x != 'undefined' & typeof y != 'undefined') {
          sumX += x;
          sumY += y;
          sumXY += x * y;
          sumX2 += x * x;
        }
      }

      const slope = (n * sumXY - sumX * sumY) / (n * sumX2 - sumX * sumX);
      const intercept = (sumY - slope * sumX) / n;

      let minX = Number.MAX_SAFE_INTEGER;
      let minY = Number.MAX_SAFE_INTEGER;
      let maxX = Number.MIN_SAFE_INTEGER;
      let maxY = Number.MIN_SAFE_INTEGER;

      for (let i = 0; i < n; i++) {
        const x = data[i].x;
        const y = slope * x + intercept;

        if (x < minX) minX = x;
        if (x > maxX) maxX = x;
        if (y < minY) minY = y;
        if (y > maxY) maxY = y;
      }

      const trendlineCoordinates = [
        { x: minX, y: minY },
        { x: maxX, y: maxY }
      ];
      return trendlineCoordinates;
    }

  },
  watch: {
    chart_data() {
      this.loadData();
    },
    selected_data() {
      this.loadData();
    },
    x_axis() {
      this.loadData();
    },
    y_axis() {
      this.loadData();
    },
    trendline() {
      this.loadData();
    },
  },
  filters: {},
};
</script>
