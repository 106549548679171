<template>
  <b-form-group :label-cols="cols">
    <template v-slot:label v-if="`${label}` != 'undefined'">
      {{ label }}
      <!-- <b-iconstack
        v-if="modelled"
        v-b-tooltip.hover.top="'Field used in modelling'"
      >
        <b-icon-asterisk stacked variant="sgs-green" scale="0.6" />
        <b-icon stacked icon="circle" variant="sgs-green"></b-icon>
      </b-iconstack>
      <b-icon-blank v-if="modelled" font-scale="0.25" /> -->
      <b-icon-exclamation-circle
        variant="sgs-red"
        v-if="required"
        v-b-tooltip.hover.top="'Required field'"
      />
      <b-icon-blank v-if="required" font-scale="0.25" />
      <b-icon-question-circle
        variant="info"
        v-if="`${help}` != 'undefined'"
        :title="`${help_title}`"
        v-b-popover.hover.top.html="`${help}`"
      />
    </template>
    <b-input-group :size="size">
      <template v-slot:append v-if="`${unit}` != 'undefined'">
        <b-input-group-text>{{ unit }}</b-input-group-text>
      </template>
      <b-form-input
        :form="form"
        :id="`${this.id}`"
        v-model="v_model"
        :type="type"
        :size="size"
        :required="required"
        :placeholder="placeholder"
        @input="updateInput"
        :list="list"
        @change="change"
        :state="state"
      >
        <template v-slot:invalid-feedback>
          {{ invalid_feedback }}
        </template>
        <template v-slot:valid-feedback></template>
      </b-form-input>
    </b-input-group>
  </b-form-group>
</template>

<script>
export default {
  name: "InputText",
  props: {
    id: String,
    label: String,
    value: [Number, String],
    form: { type: String },
    type: { type: String, default: "text" },
    unit: String,
    invalid_feedback: String,
    state: { type: Boolean, default: null },
    size: { type: String, default: "md" },
    cols: { type: [String, Number], default: 4 },
    placeholder: { type: String, default: "Enter amount" },
    help_title: { type: String, default: "" },
    help: String,
    required: { type: Boolean, default: true },
    modelled: { type: Boolean, default: false },
    list: { type: [String, Array], default: null },
    change: {
      type: Function,
      default: () => {
        return null;
      },
    },
  },
  model: {
    prop: "value",
    event: "change",
  },
  mounted() {
    this.v_model = this.value;
  },
  data() {
    return {
      v_model: this.value,
    };
  },
  methods: {
    updateInput() {
      this.$emit("change", this.v_model);
    },
  },
  watch: {
    value() {
      this.v_model = this.value;
    },
  },
};
</script>

<style lang="scss" scoped>
// .form-control {
//   text-align: left;
// }
// .form-group {
//   margin-bottom: 0;
// }
</style>
