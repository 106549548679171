<template>
  <b-form-group :label-cols="cols" :description="characters_left">
    <template v-slot:label>
      {{ label }}
      <b-icon-exclamation-circle
        variant="sgs-red"
        v-if="required"
        v-b-tooltip.hover.top="'Required field'"
      />
      <b-icon-blank v-if="required" font-scale="0.25" />
      <b-icon-question-circle
        variant="info"
        v-if="`${help}` != 'undefined'"
        :title="`${help_title}`"
        v-b-popover.hover.bottomleft.html="`${help}`"
      />
    </template>
    <b-input-group>
      <b-form-textarea
        :id="`${this.id}`"
        v-model="v_model"
        type="textarea"
        :rows="rows"
        max-rows="12"
        :required="required"
        :disabled="disabled"
        :placeholder="placeholder"
        @input="updateInput"
        :state="state"
      >
        <template v-slot:invalid-feedback>
          {{ invalid_feedback }}
        </template>
        <template v-slot:valid-feedback></template>
      </b-form-textarea>
    </b-input-group>
  </b-form-group>
</template>

<script>
export default {
  name: "InputNumber",
  props: {
    id: String,
    label: String,
    value: String,
    invalid_feedback: String,
    state: { type: Boolean, default: null },
    rows: { type: [String, Number], default: 4 },
    cols: { type: [String, Number], default: 4 },
    placeholder: { type: String, default: "Enter text" },
    help_title: { type: String, default: "" },
    help: String,
    disabled: Boolean,
    required: { type: Boolean, default: false },
  },
  model: {
    prop: "value",
    event: "change",
  },
  data() {
    return {
      v_model: this.value,
    };
  },
  methods: {
    updateInput() {
      this.$emit("change", this.v_model);
    },
  },
  computed: {
    character_length() {
      if (this.v_model == null) return 0;
      else return this.v_model.length;
    },
    characters_left() {
      let char_left = 1000 - this.character_length;
      if (char_left >= 0) return char_left + " characters left";
      else return Math.abs(char_left) + " characters too long";
    },
  },
  watch: {
    value() {
      this.v_model = this.value;
    },
  },
};
</script>
