<template>
  <div>
    <PageTitle
      variant="sgs-green"
      title="View"
      text="Find an opportunity record <br/> to edit, copy, view, delete, <br/> or create a scenario from."
    ></PageTitle>
    <b-overlay :show="!loaded" rounded="sm" no-wrap />
    <b-container v-if="loaded">
      <b-row>
        <b-col class="text-right">
          <b-button variant="sgs-red" href="create">
            Create Opportunity
            <b-icon-plus-circle-fill />
          </b-button>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col>
          <Table
            :filter_toggle="true"
            :fields="fields"
            :items="items"
            :option1="companies"
            :option2="owners"
            :option3="['FDI', 'DI', 'FDI/DI']"
            :option1_name="`company`"
            :option1_label="`Company`"
            :option2_name="`opportunity_owner`"
            :option2_label="`Owner`"
            :option3_name="`investment_type`"
            :option3_label="`FDI & DI`"
            object="opportunity"
            @deleteObject="deleteOpportunity($event)"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";
import PageTitle from "@/components/PageTitle.vue";

export default {
  name: "opportunity_list",
  components: {
    Table,
    PageTitle,
  },
  data() {
    return {
      loaded: false,
      fields: [
        { key: "id", sortable: false },
        // { key: "opportunity_code", sortable: true },
        {
          key: "opportunity_name",
          label: "Name",
          sortable: true,
        },
        { key: "team", sortable: true },
        { key: "stage", sortable: true },
        { key: "company", sortable: true },
        { key: "del", label: "", sortable: false },
      ],
      items: [],
      companies: [],
      owners: [],
      show: false,
    };
  },
  created() {
    this.$store
      .dispatch("listOpportunity")
      .then((response) => {
        this.items = response.data.opportunities;
        this.$store.dispatch("getCompanies").then((response) => {
          this.companies = response.data.companies;
          this.owners = response.data.owners;
          this.loaded = true;
        });
      })
      .catch((error) => {
        this.serverError = error.response.data;
      });
  },
  methods: {
    deleteOpportunity(event) {
      // event.preventDefault();
      this.loaded = false;
      this.$store
        .dispatch("deleteOpportunity", {
          id: event.selected_id,
        })
        .then(() => {
          this.items.splice(
            this.items.findIndex((item) => item.id === event.selected_id),
            1
          );
        })
        .catch((error) => {
          if (!error.response) {
            console.log("There was a problem in the server.");
          }
          console.log(error.response.data);
          alert(error.response.data);
        })
        .finally(() => {
          this.loaded = true;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
