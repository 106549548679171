<template>
  <div>
    <b-card no-body>
      <b-tabs
        pills
        card
        vertical
        justified
        nav-wrapper-class="col-2"
        active-nav-item-class="bg-sgs-red text-white"
        v-model="tabIndex"
      >
        <b-tab title="Investor Information">
          <b-form @submit="next" @reset="onReset">
            <h5>Company</h5>

            <InputText
              id="text-company-name"
              v-model="form.company"
              label="Company name:"
              list="list-company"
              :state="company_state"
              placeholder="Enter company name"
              invalid_feedback="company_state"
            />

            <b-datalist id="list-company">
              <option v-for="company in companies" :key="company">
                {{ company }}
              </option>
            </b-datalist>

            <InputText
              :required="false"
              id="text-website"
              v-model="form.website"
              type="url"
              label="Website:"
              placeholder="https://www.example.com"
            />

            <InputText
              :required="false"
              id="text-address"
              v-model="form.address"
              label="Victorian Address:"
              placeholder="Enter address"
            />

            <InputSelect
              v-model="form.country"
              label="Country of Investor:"
              :options="countries"
              placeholder="Select a country"
            />

            <h5>Contact Person</h5>

            <InputText
              id="text-contact-name"
              v-model="form.contact_name"
              label="Name:"
              placeholder="Enter name of contact person"
            />

            <InputText
              :required="false"
              id="text-contact-position"
              v-model="form.contact_position"
              label="Title / Position:"
              placeholder="Enter the contact person's title or positon"
            />

            <InputText
              id="text-contact-phone"
              v-model="form.contact_phone"
              type="tel"
              label="Phone:"
              placeholder="Enter the contact person's phone number"
            />

            <InputText
              id="text-contact-phone"
              v-model="form.contact_email"
              label="E-mail:"
              type="email"
              placeholder="Enter the contact person's e-mail"
            />
            <b-row>
              <b-col cols="4" />
              <b-col> </b-col>
              <b-col class="text-center">
                <b-button type="reset" variant="danger" block>Reset</b-button>
              </b-col>
              <b-col>
                <b-button ref="tab0" type="submit" variant="primary" block>
                  Next
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-tab>
        <b-tab title="Opportunity Information">
          <b-form @submit="onSubmit" @reset="onReset">
            <h5>Opportunity Information</h5>

            <InputText
              id="text-opportunity_code"
              v-model="form.opportunity_code"
              label="Outcome Ref #:"
              placeholder="Enter a code for this opportunity"
              help="Enter the corresponding <br/>code from GEMS"
              :state="opp_code_state"
              invalid_feedback="This field must be 5-12 characters long."
            />
            <InputText
              id="text-opportunity_name"
              v-model="form.opportunity_name"
              label="Opportunity Name:"
              placeholder="Enter name of opportunity"
              :state="opp_name_state"
              invalid_feedback="This field must be 5-50 characters long."
            />
            <InputSelect
              v-model="form.fyear"
              label="Financial year:"
              :options="fyears"
              placeholder="Select financial year"
            />
            <InputSelect
              v-model="form.investment_type"
              label="Investment Type:"
              :options="investment_types"
              placeholder="Select whether this is a foreign or domestic direct investment"
            />
            <InputSelect
              v-model="form.opportunity_type"
              label="Type:"
              :options="opportunity_types"
              placeholder="Select type of opportunity"
            />
            <InputSelect
              v-model="form.stage"
              label="Stage:"
              :options="stages"
              placeholder="Select stage"
            />
            <InputTextArea
              :required="false"
              id="textarea-description"
              v-model="form.description"
              label="Description:"
              placeholder="Describe the opportunity..."
              :rows="6"
              :state="desc_state"
              invalid_feedback="You have reached the character limit of this field."
            />

            <h5>Assigned to</h5>

            <InputText
              v-model="form.opportunity_owner"
              id="input-group-4"
              label="Owner:"
              list="list-owner"
              :state="opp_owner_state"
              placeholder="Who owns this opportunity?"
              invalid_feedback="This field must be 5-50 characters long."
            />
            <b-datalist id="list-owner">
              <option v-for="owner in owners" :key="owner">
                {{ owner }}
              </option>
            </b-datalist>

            <InputSelect
              v-model="form.team"
              label="Team:"
              :options="teams"
              placeholder="Select team"
            />
            <b-row>
              <b-col cols="4" />
              <b-col>
                <b-button @click="previous" variant="secondary" block>
                  Previous
                </b-button>
              </b-col>
              <b-col class="text-center">
                <b-button type="reset" variant="danger" block>Reset</b-button>
              </b-col>
              <b-col>
                <b-button type="submit" variant="primary" block>
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import InputSelect from "@/components/form/InputSelect.vue";
import countries from "@/data/country.json";
import stages from "@/data/stage.json";
import opportunity_types from "@/data/type.json";
import investment_types from "@/data/investment_type.json";
import fyears from "@/data/financial_years.json";
import teams from "@/data/team.json";
import InputText from "@/components/form/InputText.vue";
import InputTextArea from "@/components/form/InputTextArea.vue";

// import FormValidationOpportunity from "@/components/FormValidationOpportunity.vue";

export default {
  name: "OpportunityForm",
  props: ["companies", "owners", "data"],
  components: {
    InputText,
    InputSelect,
    InputTextArea,
  },
  data() {
    return {
      form: {
        // first tab
        company: "",
        website: "",
        address: "",
        country: null,
        contact_name: "",
        contact_position: "",
        contact_phone: "",
        contact_email: "",
        // second tab
        opportunity_type: null,
        opportunity_code: "",
        opportunity_name: "",
        type: null,
        fyear: 2025,
        investment_type: null,
        stage: null,
        description: "",
        opportunity_owner: "",
        team: null,
        // third tab
        // rating: 5,
        // comments: "",
      },
      fyears,
      teams,
      opportunity_types,
      investment_types,
      stages,
      countries,
      show: true,

      // states
      opp_name_state: null,
      opp_code_state: null,
      opp_owner_state: null,
      company_state: null,
      desc_state: null,
      tabIndex: 0,
    };
  },

  methods: {
    onReset() {
      // Reset our form values
      this.form.team = null;
      this.form.opportunity_name = "";
      this.form.opportunity_code = "";
      this.form.opportunity_owner = "";
      this.form.type = null;
      this.form.company = "";
      this.form.country = null;
      this.form.opportunity_type = null;
      this.form.investment_type = null;
      this.form.description = "";
      this.form.stage = null;
      this.form.is_complete = false;

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.validate();
      if (
        [
          this.opp_name_state,
          this.opp_code_state,
          this.opp_owner_state,
          this.company_state,
        ].every((i) => i)
      ) {
        this.$emit("onSubmit", this.form);
      }
    },
    validate() {
      if (
        this.form.opportunity_code.length < 5 ||
        this.form.opportunity_code.length > 12
      ) {
        this.opp_code_state = false;
      }
      if (
        this.form.opportunity_name.length < 5 ||
        this.form.opportunity_name.length > 50
      ) {
        this.opp_name_state = false;
      }
      if (
        this.form.opportunity_owner.length < 5 ||
        this.form.opportunity_owner.length > 50
      ) {
        this.opp_owner_state = false;
      }
      if (this.form.company.length < 4 || this.form.company.length > 50) {
        this.company_state = false;
      }
      if (this.form.description.length > 1000) {
        this.desc_state = false;
      }
    },
    next(event) {
      event.preventDefault();
      this.tabIndex++;
    },
    previous() {
      this.tabIndex--;
    },
  },
  beforeMount() {
    if (this.data) {
      this.form = this.data;
    }
  },
  computed: {
    characters_left() {
      let char_left = 1000 - this.form.description.length;
      if (char_left >= 0) return char_left + " characters left";
      else return Math.abs(char_left) + " characters too long";
    },
  },
  watch: {
    "form.opportunity_code": function(val) {
      if (val.length > 4 && val.length < 13) {
        this.opp_code_state = true;
      }
      if (val.length > 12) {
        this.opp_code_state = false;
      }
    },
    "form.opportunity_name": function(val) {
      if (val.length > 4 && val.length < 51) {
        this.opp_name_state = true;
      }
      if (val.length > 50) {
        this.opp_name_state = false;
      }
    },
    "form.opportunity_owner": function(val) {
      if (val.length > 4 && val.length < 51) {
        this.opp_owner_state = true;
      }
      if (val.length > 50) {
        this.opp_owner_state = false;
      }
    },
    "form.description": function(val) {
      if (val.length > 4 && val.length < 1001) {
        this.desc_state = true;
      }
      if (val.length > 1000) {
        this.desc_state = false;
      }
    },
    "form.company": function(val) {
      if (val.length > 3 && val.length < 51) {
        this.company_state = true;
      }
      if (val.length > 50) {
        this.company_state = false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
