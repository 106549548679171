<template>
  <div>
    <PageTitle
      variant="sgs-red"
      title="Create"
      text="Fill in the information in each of the tabs <br/> below to create a scenario for your opportunity."
    ></PageTitle>
    <b-overlay :show="loading" rounded="sm" no-wrap />
    <b-container v-if="loaded">
      <OpportunityScenarioForm
        @onSubmit="onSubmit($event)"
        @submitDisqualified="submitDisqualified($event)"
      />
    </b-container>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import OpportunityScenarioForm from "@/components/OpportunityScenarioForm.vue";

export default {
  name: "CreateOpportunityScenario",
  components: {
    OpportunityScenarioForm,
    PageTitle,
  },
  data() {
    return {
      loading: false,
      loaded: true,
    };
  },
  methods: {
    submitDisqualified(event) {
      this.loading = true;
      this.$store
        .dispatch("createScenarioDisqualified", {
          id: this.$route.params.id,
          data: event,
        })
        .then((response) => {
          this.loading = false;
          console.log(response);
          this.$router.push({
            name: "view_opportunity",
            params: {
              id: this.$route.params.id,
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          alert(error.response.data);
          this.serverError = error.response.data;
        });
    },
    onSubmit(event) {
      this.loading = true;
      this.$store
        .dispatch("createScenario", {
          id: this.$route.params.id,
          data: event,
        })
        .then((response) => {
          this.loading = false;
          this.$router.push({
            name: "view_assessment",
            params: {
              id: response.data.public_id,
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          alert(error.response.data);
          this.serverError = error.response.data;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
