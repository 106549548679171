<template>
  <div>
    <PageTitle variant="sgs-green" title="View" text="Here's the assessment for your scenario."></PageTitle>
    <b-overlay :show="!loaded" rounded="sm" no-wrap></b-overlay>
    <b-container v-if="loaded" class="assessment_content">
      <b-row>
        <b-col>
          <b-alert :show="is_methodology_old" variant="danger">
            <h4 class="alert-heading">
              <b-icon-exclamation-triangle-fill />
              The measures on this record is based on an old method.
            </h4>
            <p>This assessment was generated on {{ this.form.timestamp }}.</p>
            <p>
              Update this record and fill in the missing inputs to apply the
              updated BP3 methodology.
            </p>
          </b-alert>
        </b-col>
      </b-row>
      <b-row class="buttons">
        <b-col class="text-right">
          <!-- <b-col class="d-flex justify-content-between align-items-center"> -->
          <!-- <h3>Assessment</h3> -->
          <b-button variant="primary" :href="`/assessment/compare/${$route.params.id}`">
            Compare
            <b-icon-file-earmark-bar-graph variant="white" shift-h="3px" scale=".9" shift-v="1px" />
          </b-button>
          <b-icon-blank/>
          <b-button v-b-modal.verify variant="sgs-pink">
            Verify
          </b-button>
          <b-modal centered no-stacking size="xl" id="verify" title="Verification E-mail Preview">
            <b-alert show variant="info">
              Click on "Create e-mail draft" and paste the contents of this preview.
            </b-alert>
            <div id="email_template" style="font-family: Calibri; font-size: 1rem">
              <p>Hello <mark>{{ form.contact_name }}</mark>,</p>
              <p>Many thanks for your time <mark>today</mark>.</p>
              <p>
                We are delighted that <mark>{{ form.company }}</mark>’s investment in Victoria 
                is now confirmed to proceed. All of us at Invest Victoria are pleased to have 
                partnered with you to make this <mark>{{ form.opportunity_type.toLowerCase() }}</mark> 
                a reality. <mark>[extra detail regarding whatever is next in terms of support 
                re announcement/or further introductions]</mark>.
              </p>
              <p>
                As discussed throughout this process of working together, the Victorian Government 
                provides this free support service to <mark>{{ form.company }}</mark> because of 
                the significant economic impact that your investment brings into the State. It is 
                important that we can validate the final investment metrics with you in order to 
                articulate your investment to Government and ensure the services we provide continue 
                to be appropriately supported and championed. The information provided will be kept 
                confidential and not shared with any third parties unless otherwise requested from 
                yourselves.
              </p>
              <p>
                Please confirm that we have characterised the final details of your 
                project correctly or if there is any critical detail you feel has 
                been overlooked:
              <ul>
                <li>You expect this project will employ <mark>{{ this.format(form.direct_emp, "0,0") }}</mark> people</li>
                <li>
                  <mark>{{ this.format(form.direct_emp_tech, "0,0") }}</mark> Victorian employees will be in
                  <mark>[technical/developer/engineering]</mark> roles
                </li>
                <li>
                  The average salary will be <mark>{{ this.format(form.ave_salary, "$0,0") }}</mark>
                </li>
                <li>
                  Overall capital expenditure will be <mark>{{ this.format(form.capex, "$0,0") }}</mark>
                </li>
                <!-- <li>
                  Overall R&D expenditure will be <mark>XX</mark>
                </li> -->
              </ul>
              </p>
              <p>
                It has been a pleasure working with <mark>you</mark> on your project to date and
                it would be great to arrange a time to speak again <mark>soon</mark>.
              </p>
              <br />
              <p>Best wishes,</p>
              <p>{{ form.opportunity_owner }}</p>
            </div>
            <template v-slot:modal-footer="{ cancel }">
              <b-col>
                <!-- <b-link :href="mail_head"> -->
                <b-button variant="success" @click="copyToClipboard()" type="submit" block>Create e-mail draft
                </b-button>
                <!-- </b-link> -->
              </b-col>
              <b-col>
                <b-button variant="danger" @click="cancel()" block>Close</b-button>
              </b-col>
            </template>
          </b-modal>
          <b-icon-blank />
           <ExportData :data="JSON.parse(export_data)" 
           :name="`${this.form.opportunity_name} - ${this.form.scenario} - ${this.form.timestamp}.csv`" />
          <b-icon-blank />
          <b-button variant="primary" @click="print">
            Print
            <b-icon-printer-fill shift-h="3px" scale=".9" shift-v="1px" />
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <SummaryCard class="item" :editable="true" :form="form" object="scenario" />
        </b-col>
        <b-col>
          <SummaryMeasure class="item" header="BP3 Measures" :items="expenditure" :more_items="expenditure[3].calc" />
          <SummaryMeasure class="item" header="Summary Measures" :items="measures" :more_items="totals" />
          <Grantcard class="item" :editable="true" :form="form" object="scenario" />
          <ScoreCard class="item" :items="strategic_outcomes" :aggregate="aggregate" :data_quality="data_quality" :benchmarks="benchmarks" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-card no-body>
            <template v-slot:header>
              <b-card-title class="d-flex justify-content-between align-items-center">
                Marginal Economic Impact (GSP)
                <b-icon-question-circle variant="secondary" title="Source of GSP Uplift"
                  v-b-popover.hover.bottomleft.html="
                    `Click on the legend to hide or show them in the chart.`
                  " />
              </b-card-title>
            </template>
            <b-card-body>
              <LineChart :chart_data="timeline" style="width: 100%" />
            </b-card-body>
          </b-card>
          <br />
        </b-col>
      </b-row>
      <b-row class="timestamp">
        <b-col>
            <h4>Glossary</h4>
            <Table
            :filter_toggle="false"
            :fields="fields"
            :items="items"
          />
        </b-col>
      </b-row>
      <b-row class="timestamp">
        <b-col class="text-center"> Assessed on {{ form.timestamp }} </b-col>
      </b-row>
    </b-container>
    <b-modal id="feedback" title="Send Feedback" centered no-stacking>
      <!-- <b-input v-model="feedback.url" disabled></b-input> -->
      <InputTextArea id="text-feedback" v-model="feedback.feedback" cols="0" placeholder="Write your feedback." />
      <template v-slot:modal-footer="{ cancel }">
        <b-col>
          <b-button variant="danger" @click="cancel()" block>Cancel</b-button>
        </b-col>
        <b-col>
          <b-button variant="primary" @click="send()" :disabled="feedback.feedback == null" block>Send</b-button>
        </b-col>
      </template>
    </b-modal>
    <b-modal id="confirm_feedback" title="Feedback sent" centered no-stacking>
      Thank you for your feedback.
      <template v-slot:modal-footer>
        <b-button variant="primary" @click="$bvModal.hide('confirm_feedback')" block>Close</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
var numeral = require("numeral");
import SummaryCard from "@/components/SummaryCard";
import InputTextArea from "@/components/form/InputTextArea";
import ScoreCard from "@/components/ScoreCard";
import SummaryMeasure from "@/components/SummaryMeasure";
import Grantcard from "@/components/GrantCard";
import LineChart from "@/components/charts/LineChart";
import PageTitle from "@/components/PageTitle.vue";
import Table from "@/components/Table.vue";
import ExportData from "@/components/ExportData.vue";

export default {
  name: "StartAssessment",
  components: {
    SummaryCard,
    SummaryMeasure,
    Grantcard,
    ScoreCard,
    LineChart,
    PageTitle,
    InputTextArea,
    Table,
    ExportData
  },
  data() {
    return {
      loaded: false,
      form: {},
      strategic_outcomes: [{}],
      aggregate: { measure: "Strategic Impact Score", score: 0 },
      data_quality: { measure: "Data Quality Score", score: 0 },
      strategic_outcomes_fields: ["measure", "score"],
      measures: [],
      expenditure: [],
      timeline: [],
      export_data: [],
      mailto_items: [{}],
      benchmarks: null,
      feedback: {
        url: window.location.pathname,
        email: null,
        feedback: null,
      },
      fields: ["term", "definition"],
      items: [
        {
          "term": "Innovation Expenditure",
          "definition": "The annual operational expenditure plus total capital expenditure, given that the project is considered innovative."
        },
        {
          "term": "GSP Uplift",
          "definition": "The net present value of the gross state product (GSP) uplift induced by the project. It includes both direct and indirect impacts."
        },
        {
          "term": "State tax revenue",
          "definition": "The net present value of the state tax revenue uplift induced by the project. It includes both direct and indirect impacts."
        },
        {
          "term": "Total jobs created",
          "definition": "The annual number of additional FTE jobs (both direct and indirect) supported by the project once fully operational."
        },
        {
          "term": "Indirect jobs created",
          "definition": "The annual number of FTE jobs created in related industries as a result of the project (i.e. flow on jobs)"
        },
      ]
    };
  },
  created() {
    this.$store
      .dispatch("getAssessment", {
        data: this.$route.params.id,
      })
      .then((response) => {
        this.form = response.data.assessment;
        console.log(response.data);
        this.form.capability = this.split_array(this.form.capability);
        this.strategic_outcomes = this.form.strategic_outcomes;
        this.aggregate.score = this.form.strategic_impact_score;
        this.data_quality.score = this.form.data_quality_score;
        this.measures = this.form.measures;
        this.expenditure = this.form.expenditure;
        this.totals = this.form.totals;
        this.timeline = this.form.timed_eia_result;
        this.export_data = this.form.export_data;
        this.mailto_items = this.form.mailto_items;
        this.benchmarks = this.form.benchmarks;
        this.loaded = true;
        this.form.timestamp.toString().replace(" AEST", "");
      });
  },
  methods: {
    copyToClipboard() {
      var range = document.createRange();
      var item = document.getElementById("email_template");
      range.selectNode(item); //// this part
      window.getSelection().removeAllRanges();
      window.getSelection().addRange(range);
      document.execCommand("copy");
      window.getSelection().removeAllRanges();

      window.location.href = this.mail_head;
    },
    format(val, fmt) {
      return numeral(val).format(fmt);
    },
    split_array(str) {
      if (str != null) {
        return str.split('","');
      } else {
        return null;
      }
    },
    print() {
      // resize radar chart
      var radar = document.getElementById("radar-chart");
      var radar_height = radar.style.height;
      var radar_width = radar.style.width;
      radar.style.width = "100%";
      radar.style.height = "100%";

      // resize line chart
      var line = document.getElementById("line-chart");
      var line_height = line.style.height;
      var line_width = line.style.width;
      line.style.width = "100%";
      line.style.height = "100%";

      window.print();

      // revert to original values
      radar.style.width = radar_height;
      radar.style.height = radar_width;
      line.style.width = line_width;
      line.style.height = line_height;
    },
    send() {
      this.$bvModal.hide("feedback");
      this.loading = true;
      this.$store
        .dispatch("sendFeedback", {
          data: this.feedback,
        })
        .then((response) => {
          this.loading = false;
          console.log(response.data);
          this.$bvModal.show("confirm_feedback");
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.response.data);
        });
    },
  },
  computed: {
    is_methodology_old() {
      var method = this.expenditure[3].badge;
      // var assessment_date = new Date(
      //   this.form.timestamp.toString().replace(" AEST", "")
      // );
      // var cutoff = new Date(2022, 7, 1);
      return method == "Old method";
    },
    mail_head() {
      let text = "mailto:";
      let email = this.form.contact_email;
      if (email != null) {
        text += email;
      }
      text += "?subject=[Invest Victoria] Verification";
      text += "&body=";
      return text;
    },
    mail_body() {
      let text = "mailto:";
      let email = this.form.contact_email;
      if (email != null) {
        text += email;
      }
      text += "?subject=[Invest Victoria] Verification";
      text += "&body=";
      this.mailto_items.forEach((x) => {
        let key = Object.keys(x)[0];
        let val = Object.values(x)[0];
        if (val != null) {
          text += encodeURI(key) + encodeURIComponent(val) + encodeURI("\n");
        }
      });
      return text;
    },
  },
  mounted() { },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.item {
  padding: 0.5em 0em;
}

.col {
  padding-left: 8px;
  padding-right: 8px;
}

.row {
  padding-bottom: 0.5em;
}

.timestamp {
  display: none;
}

@media print {
  .item {
    width: 467px;
    padding: 0.3em 0em;
  }

  .col {
    padding-left: 7px;
    padding-right: 7px;
  }

  .row {
    &:first-child {
      display: none;
    }
  }

  .card-title {
    font-size: 1.2em;
  }

  .card-header {
    padding-bottom: 0.2em;
  }

  .card-body {
    padding-top: 0.3em;
    padding-bottom: 0.2em;
  }

  .timestamp {
    display: flex;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .hanging-bar {
    display: none;
  }

  .buttons {
    display: none;
  }
}
</style>
