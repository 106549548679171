<template>
  <div>
    <PageTitle
      variant="sgs-orange"
      title="Portfolio"
      text="See aggregated impacts across claimed projects"
    ></PageTitle>
    <b-overlay :show="!loaded" rounded="sm" no-wrap />
    <b-container>
      <b-row>
        <b-col cols="4" class="mt-2">
          <b-card>
            <b-form-group label="FDI/DI:" label-cols="5">
              <b-input-group>
                <b-select
                  v-model="search.investment_type"
                  :options="investment_types"
                >
                  <template v-slot:first>
                  <b-form-select-option :value="null">
                    Select FDI / DI
                  </b-form-select-option>
                </template>
                </b-select>
              </b-input-group>
            </b-form-group>

            <b-form-group label="Financial Year:" label-cols="5">
              <b-input-group>
                <b-select
                  v-model="search.fyear"
                  :options="fyears"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="null">
                      Select FY
                    </b-form-select-option>
                  </template>
                </b-select>
              </b-input-group>
            </b-form-group>

            <b-form-group label="Team:" label-cols="5">
              <b-input-group>
                <b-select v-model="search.team" :options="team_data">
                  <template v-slot:first>
                    <b-form-select-option :value="null">
                      All teams
                    </b-form-select-option>
                  </template>
                </b-select>
              </b-input-group>
            </b-form-group>

            <b-form-group label="Cost type:" label-cols="5">
              <b-input-group>
                <b-select v-model="search.cost_type" :options="cost_types">
                </b-select>
              </b-input-group>
            </b-form-group>

            <b-form-group label="Sector:" label-cols="5">
              <b-input-group>
                <b-select v-model="search.sector" :options="sectors">
                  <template v-slot:first>
                    <b-form-select-option :value="null">
                      Select sector
                    </b-form-select-option>
                  </template>
                </b-select>
              </b-input-group>
            </b-form-group>

            <!-- <b-form-group label="Project status:" label-cols="5">
              <b-input-group>
                <b-select v-model="search.is_complete" :options="yes_no">
                  <template v-slot:first>
                  <b-form-select-option :value="null">
                    Show all
                  </b-form-select-option>
                </template>
                </b-select>
              </b-input-group>
            </b-form-group> -->
            <b-button variant="sgs-blue" block @click="getPortfolio">
              Search
              <b-icon-search />
            </b-button>
          </b-card>
        </b-col>
        <b-col cols="8">
          <b-jumbotron v-if="!data_found" class="mt-2 text-center text-muted">
            <template v-slot:lead>
              No projects were found, try searching with other filters
            </template>
          </b-jumbotron>
          <SummaryMeasure v-if="data_found" class="item" header="Overview" :items="grant" />
          <SummaryMeasure v-if="data_found" class="item" header="BP3 Measures" :items="bp3" />
          <SummaryMeasure
            v-if="data_found"
            class="item"
            header="Summary Measures"
            :items="measures"
            :more_items="totals"
          />
        </b-col>
      </b-row>
      <b-row v-if="data_found">
        <b-col>
          <b-card no-body class="mt-2">
            <template v-slot:header>
              <b-card-title
              class="d-flex justify-content-between align-items-center"
              >
              Marginal Economic Impact (GSP)
                <b-icon-question-circle
                  variant="secondary"
                  title="Source of GSP Uplift"
                  v-b-popover.hover.bottomleft.html="
                  `Click on the legend to hide or show them in the chart.`
                  "
                  />
                </b-card-title>
              </template>
              <b-card-body>
                <LineChart v-if="loaded" :chart_data="timeline" />
              </b-card-body>
            </b-card>
          </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import LineChart from "@/components/charts/LineChart";
import SummaryMeasure from "@/components/SummaryMeasure";
import team_data from "@/data/team_search.json";
import types_data from "@/data/type.json";
import fyears from "@/data/financial_years.json";
// import ExportData from "@/components/ExportData.vue";
import sectors from "@/data/sector.json";
import investment_types from "@/data/investment_type.json";

export default {
  name: "Portfolio",
  components: {
    // ExportData,
    LineChart,
    PageTitle,
    SummaryMeasure,
  },
  data() {
    return {
      loaded: true,
      serverError: "",
      search: {
        fyear: null,
        investment_type: "FDI",
        opp_type: null,
        team: null,
        cost_type: null,
        sector: null,
        is_complete: false,
      },
      fyears,
      team_data,
      types_data,
      sectors,
      investment_types,
      timeline: [],
      data_found: false,
      bp3: [
        {
          measure: "State tax revenue per dollar support",
          value: 10000,
          unit: "Value per dollar of cost to government",
          format: "$0,0",
        },
      ],
      grant: [
        {
          measure: "Projects",
          value: 10000,
          unit: "",
          format: "0,0",
        },
      ],
      measures: [
        {
          measure: "State tax revenue per dollar support",
          value: 10000,
          unit: "Value per dollar of cost to government",
          format: "$0,0",
        },
      ],
      totals: [
        {
          measure: "State tax revenue per dollar support",
          value: 10000,
          unit: "Value per dollar of cost to government",
          format: "$0,0",
        },
      ],

      cost_types: [
        { text: "Show all", value: null },
        { text: "Grant only", value: "Grant" },
        { text: "Facilitation only", value: "Facilitation" },
        { text: "Both only", value: "Both" },
      ],
      yes_no: [
        { text: "Complete projects only", value: true },
        { text: "Complete and claimed projects only", value: false },
      ],
    };
  },
  methods: {
    getPortfolio() {
      this.loaded = false;
      this.$store
        .dispatch("post", {
          url: "/portfolio",
          data: this.search,
        })
        .then((response) => {
          console.log(response.data)
          if (response.data.assessment) {
            this.form = response.data.assessment;
            this.bp3 = this.form.bp3;
            this.grant = this.form.grant;
            this.measures = this.form.measures;
            this.totals = this.form.totals;
            this.timeline = this.form.timeline;
            this.serverError = "";
            this.data_found = true;
          } else {
            this.data_found = false;
          }
          this.loaded = true;
        })
        .catch((error) => {
          this.serverError = error.response.data;
        });
    },
    print() {
      // resize radar chart
      var chart = document.getElementsByClassName("chartjs-render-monitor");

      chart.forEach((e) => {
        e.style.width = "100%";
        e.style.height = "100%";
      });

      window.print();
    },
  },
  mounted() {
    this.getPortfolio();
  },
  created() {
    // this.$store.dispatch("getCompanies").then((response) => {
    //   this.companies = response.data.companies;
    //   this.owners = response.data.owners;
    //   this.loaded = true;
    // });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.item {
  padding: 0.5em 0em;
}

@media print {
  .item {
    width: 467px;
    padding: 0.3em 0em;
  }

  .col {
    padding-left: 7px;
    padding-right: 7px;
  }

  .row {
    &:first-child {
      display: none;
    }
  }

  .card-title {
    font-size: 1.2em;
  }

  .card-header {
    padding-bottom: 0.2em;
  }

  .card-body {
    padding-top: 0.3em;
    padding-bottom: 0.2em;
  }

  .timestamp {
    display: flex;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .hanging-bar {
    display: none;
  }
  .buttons {
    display: none;
  }
  .search {
    display: none;
  }
  .axis_select {
    display: none;
  }
}
</style>
