<template>
  <div>
    <b-row v-if="filter_toggle" class="table_filter">
      <b-col>
        <b-row>
          <b-col>
            <b-form-group
              label="Filter"
              label-cols="1"
              label-align="right"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group>
                <b-form-input
                  v-model="filter"
                  type="search"
                  id="filterInput"
                  placeholder="Type to Search"
                ></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">
                    Clear
                    <b-icon-x />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <br />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="2">
        <b-select v-model="perPage" :options="rows"></b-select>
      </b-col>
      <slot name="params" />
    </b-row>
    <b-row v-if="option1 != null">
      <b-col>
        <b-form-group
          :label="option1_label"
          label-cols="2"
          label-align="right"
          class="mb-0"
        >
          <b-select v-model="filter1" :options="option1">
            <template v-slot:first>
              <b-form-select-option value="">
                View all
              </b-form-select-option>
            </template>
          </b-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          :label="option2_label"
          label-cols="2"
          label-align="right"
          class="mb-0"
        >
          <b-select v-model="filter2" :options="option2">
            <template v-slot:first>
              <b-form-select-option value="">
                View all
              </b-form-select-option>
            </template>
          </b-select>
        </b-form-group>
        <br />
      </b-col>
      <b-col cols="2">
        <b-form-group v-if="option3 != null" label-align="right" class="mb-0">
          <b-select v-model="filter3" :options="option3">
            <template v-slot:first>
              <b-form-select-option value="">
                View all {{ option3_label }}
              </b-form-select-option>
            </template>
          </b-select>
        </b-form-group>
        <br />
      </b-col>
    </b-row>
    <b-table
      v-if="(totalRows > 0) | (items.length > 0)"
      id="opportunity_list"
      striped
      head-variant="light"
      hover
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter_string"
      @filtered="onFiltered"
      :selectable="object == 'assessment'"
      :filter-function="filter_function"
      @row-selected="onRowSelected"
      ref="selectableTable"
      select-mode="multi"
      outlined
      sort-icon-left
      responsive
    >
      <template v-slot:head(id)>
        <b-checkbox
          ref="select_all"
          v-model="select_all"
          :indeterminate.sync="indeterminate"
          v-if="(object === 'assessment') & (items.length > 1)"
          v-b-popover.hover.top-left="'Select / Deselect all'"
        />
        <div v-else-if="object != 'assessment'">Actions</div>
      </template>
      
      <template v-slot:cell(claim)="data">
        <b-badge 
        v-if="data.item.hasOwnProperty('assessment')"
        v-b-modal.prefer_scenario
        @click="input.selected_id = `${data.item.id}`"
        size="sm"
        :variant="data.item.preferred ? 'sgs-green' : 'secondary'"
      >
      <b-icon-star-fill scale=".8" variant="white" />
        {{ data.item.preferred ? 'Claimed ' + fy(data.item.fyear) : 'Claim'}}
      </b-badge>
    
      <b-icon-blank v-else />

      </template>
      <template v-slot:cell(id)="data">
        <div v-if="object === 'opportunity'" class="d-flex">
          <b-link :href="`/opportunity/${data.value}/edit`">
            <b-iconstack v-b-popover.hover.top="'Edit opportunity'">
              <b-icon-square-fill variant="sgs-yellow" />
              <b-icon-pencil-square scale=".8" variant="white" />
            </b-iconstack>
          </b-link>
          <b-link :href="`/opportunity/create/${data.value}`">
            <b-iconstack v-b-popover.hover.top="'Clone opportunity'">
              <b-icon-square-fill variant="sgs-orange" />
              <b-icon-clipboard-plus scale=".8" variant="white" />
            </b-iconstack>
          </b-link>
          <b-link :href="`/opportunity/${data.value}/scenario/create`">
            <b-iconstack v-b-popover.hover.top="'Create a scenario'">
              <b-icon-square-fill variant="sgs-blue" />
              <b-icon-layers-fill scale=".75" variant="white" />
            </b-iconstack>
          </b-link>
        </div>
        <div v-if="object === 'scenario'" class="d-flex">
          <b-link :href="`/scenario/${data.value}/edit`">
            <b-iconstack v-b-popover.hover.top="'Edit scenario'">
              <b-icon-square-fill variant="sgs-yellow" />
              <b-icon-pencil-square scale=".8" variant="white" />
            </b-iconstack>
          </b-link>
          <b-link :href="`${$route.params.id}/scenario/create/${data.value}`">
            <b-iconstack v-b-popover.hover.top="'Clone scenario'">
              <b-icon-square-fill variant="sgs-orange" />
              <b-icon-clipboard-plus scale=".8" variant="white" />
            </b-iconstack>
          </b-link>
         
          <b-link
            v-if="data.item.hasOwnProperty('assessment')"
            :href="`/assessment/compare/${data.item.assessment}`"
          >
            <b-iconstack v-b-popover.hover.top="'Compare scenario'">
              <b-icon-square-fill variant="sgs-blue" />
              <b-icon-file-earmark-bar-graph scale=".8" variant="white" />
            </b-iconstack>
          </b-link>
          <b-icon-blank v-else />
          <b-link
          v-b-modal.delete_object
          @click="input.selected_id = `${data.item.id}`"
        >
          <b-icon-x-square-fill
            v-b-popover.hover.top="'Delete ' + `${object}`"
            variant="danger"
          />
        </b-link>
        </div>
        <div v-if="object === 'assessment'" class="d-flex">
          <b-checkbox
            v-if="items.length > 1"
            v-model="data.rowSelected"
            v-b-popover.hover.top="'Show in chart'"
            @change="toggleVisible(data.index, data.rowSelected)"
          />
        </div>
        <div v-if="object === 'hays'" class="d-flex">
          <b-button
            @click="
              input.selected_id = `${data.item.id}`;
              pickHays($event);
            "
            variant="warning"
            size="sm"
          >
            Select
          </b-button>
        </div>
      </template>
      <template v-slot:cell(del)="data">
        <b-link
          v-b-modal.delete_object
          @click="input.selected_id = `${data.item.id}`"
        >
          <b-icon-x-square-fill
            v-b-popover.hover.top="'Delete ' + `${object}`"
            variant="danger"
          />
        </b-link>
      </template>
      <template v-slot:cell(opportunity_name)="data">
        <b-link :href="`/opportunity/${data.item.id}`">
          {{ data.value }}
        </b-link>
      </template>
      <template v-slot:cell(scenario)="data">
        <div v-if="data.item.hasOwnProperty('assessment')">
          <b-link :href="`/assessment/${data.item.assessment}`">
            {{ data.value }}
          </b-link>
        </div>
        <div v-else>
          {{ data.value }}
        </div>
      </template>

      <template v-slot:cell(disqualified)="data">
        <div>{{ data.value | true_or_false }}</div>
      </template>
      <template v-slot:cell()="data">
        <span
          v-if="data.field.hasOwnProperty('format')"
          style="text-align: right"
        >
          {{ format_value(data.value, data.field.format) }}
        </span>
        <span v-else>
          {{ data.value }}
        </span>
      </template>
      <template v-slot:cell(opportunity_scenario)="data">
        <div class="cell-head text-left">{{ data.value }}</div>
        <b-link :href="`/assessment/${data.item.id}`">
          <div class="text-left">{{ data.item.scenario }}</div>
        </b-link>
      </template>
      <template v-slot:cell(score_data_quality)="data">
        <div class="text-right">
          <b-badge v-if="data.value <= 1.5" variant="scale-red" pill>
            {{ data.value | score }} / 5
          </b-badge>
          <b-badge v-else-if="data.value <= 2.5" variant="scale-pink" pill>
            {{ data.value | score }} / 5
          </b-badge>
          <b-badge v-else-if="data.value <= 3.5" variant="scale-yellow" pill>
            {{ data.value | score }} / 5
          </b-badge>
          <b-badge v-else-if="data.value <= 4.5" variant="scale-lime" pill>
            {{ data.value | score }} / 5
          </b-badge>
          <b-badge v-else variant="scale-green" pill>
            {{ data.value | score }} / 5
          </b-badge>
        </div>
      </template>
      <template v-slot:cell(strategic_impact_score)="data">
        <div class="text-center">
          <b-badge variant="info" pill> {{ data.value | score }} / 5 </b-badge>
          <RadarChart
            style="float: right; margin: -2px 5px -10px 0px"
            :chart_data="data.item.strategic_outcomes"
            :height="40"
            :width="40"
          />
        </div>
      </template>
      <template v-slot:cell(opportunity_code)="data" class="text-right">
        <div class="text-right">{{ data.value | code }}</div>
      </template>
    </b-table>
    <div v-else>
      <b-jumbotron v-if="object != 'assessment'" class="text-center text-muted">
        <template v-slot:lead>
          There's nothing here yet. Get started by creating an opportunity.
        </template>
      </b-jumbotron>
      <b-jumbotron
        v-else-if="object == 'scenario'"
        class="text-center text-muted"
      >
        <template v-slot:lead>
          There's nothing here yet. Let's create a scenario.
        </template>
      </b-jumbotron>
      <b-jumbotron
        v-else-if="object == 'assessment'"
        class="text-center text-muted"
      >
        <template v-slot:lead>
          There's nothing here yet. Let's do a search.
        </template>
      </b-jumbotron>
    </div>

    <b-pagination
      v-if="(totalRows > perPage) & (perPage > 0)"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      aria-controls="opportunity_list"
      align="center"
    />

    <b-modal
      id="assess_scenario"
      title="Assess Opportunity Scenario"
      hide-footer
      no-stacking
    >
      <b-button variant="primary" @click="assessScenario">Assess</b-button>
      <br />
    </b-modal>

    <b-modal id="delete_object" centered no-stacking>
      <template v-slot:modal-title v-if="object === 'opportunity'">
        Delete Opportunity
      </template>
      <template v-slot:modal-title v-else-if="object === 'scenario'">
        Delete Opportunity Scenario
      </template>
      <p v-if="object === 'opportunity'">
        Are you sure you want to permanently delete this opportunity?
        <br />All scenarios associated with this opportunity will also be
        deleted.
      </p>
      <p v-else-if="object === 'scenario'">
        Are you sure you want to permanently delete this scenario?
        <br />The assessment associated with this scenario will also be deleted.
      </p>
      <template v-slot:modal-footer="{ cancel }">
        <b-col>
          <b-button variant="danger" @click="deleteObject" block>
            Yes
          </b-button>
        </b-col>
        <b-col>
          <b-button variant="primary" @click="cancel()" block>
            No
          </b-button>
        </b-col>
      </template>
    </b-modal>
    <b-modal id="prefer_scenario" centered no-stacking>
      <template v-slot:modal-title>
        Claim Scenario
      </template>
      <p>
        Would you like to claim this scenario?
      </p>
      <b-container>
        <p class="mb-0 small">FY when project was confirmed:</p>
        <b-select
          v-model="input.fyear"
          :options="fyears"
          size="sm"
          class="mb-2"
        ></b-select>
        <!-- <b-checkbox class="mt-2" v-model="input.is_complete">
          Also mark this project as complete
        </b-checkbox> -->
      </b-container>
      <template v-slot:modal-footer="{ cancel }">
        <b-col>
          <b-button variant="primary" @click="selectClaimed" block>
            Yes
          </b-button>
        </b-col>
        <b-col>
          <b-button variant="danger" @click="cancel()" block>
            No
          </b-button>
        </b-col>
      </template>
    </b-modal>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import rows from "@/data/rows.json";
import fyears from "@/data/financial_years.json";
import RadarChart from "@/components/charts/MiniRadarChart.vue";

RadarChart.plug;
var numeral = require("numeral");

export default {
  name: "Table",
  components: { RadarChart },
  props: {
    filter_toggle: { type: Boolean, default: true },
    fields: { type: [Array, Object] },
    items: { type: [Array, Object] },
    object: { type: String },
    option1: { type: Array, default: null },
    option2: { type: Array, default: null },
    option3: { type: Array, default: null },
    option1_name: { type: String, default: null },
    option2_name: { type: String, default: null },
    option3_name: { type: String, default: null },
    option1_label: { type: String, default: null },
    option2_label: { type: String, default: null },
    option3_label: { type: String, default: null },
    perPage: { type: Number, default: 20 },
    fyear: { type: Number, default: 2025 },
  },
  data() {
    return {
      rows,
      selected: [],
      isBusy: false,
      // perPage: 20,
      currentPage: 1,
      filter: "",
      totalRows: 0,
      filter1: "",
      filter2: "",
      filter3: "",
      select_all: false,
      indeterminate: true,
      // assessment
      assessment_input: {
        selected_id: "",
        report_year: null,
      },
      input: {
        fyear: 2025,
        selected_id: "",
        is_complete: false,
      },
      fyears,
    };
  },
  mounted() {
    this.totalRows = this.items.length;
    this.input.fyear = this.fyear
  },
  methods: {
    toggleVisible(index, selected) {
      if (selected) {
        if (this.select_all) {
          this.indeterminate = true;
        }
        this.$refs.selectableTable.unselectRow(index);
      } else {
        this.$refs.selectableTable.selectRow(index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
      this.$emit("filter", this.selected);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.selected = filteredItems;
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
      this.indeterminate = true;
      this.$emit("filter", this.selected);
    },
    toggleBusy() {
      this.isBusy = !this.isBusy;
    },
    assessScenario(event) {
      event.preventDefault();
      this.$bvModal.hide("assess_scenario");
      this.$emit("assessScenario", this.assessment_input);
    },
    deleteObject(event) {
      event.preventDefault();
      this.$bvModal.hide("delete");
      this.$emit("deleteObject", this.input);
    },
    pickHays(event) {
      event.preventDefault();
      this.$emit("pickHays", this.input);
    },
    selectClaimed(event) {
      event.preventDefault();
      this.$bvModal.hide("select_preferred");
      this.$emit("selectClaimed", this.input);
    },
    filter_function_nest(row, filter) {
      if (filter != "") {
        if (
          JSON.stringify(row)
            .toLowerCase()
            .includes(filter.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    filter_function_exact(row, filter) {
      if (filter != "") {
        if (row.toLowerCase() == filter.toLowerCase()) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    filter_function(row, filter) {
      return (
        this.filter_function_nest(row, filter.text) &&
        this.filter_function_exact(row[this.option1_name], filter.filter1) &&
        this.filter_function_exact(row[this.option2_name], filter.filter2) &&
        this.filter_function_exact(row[this.option3_name], filter.filter3)
      );
    },
    format_value(value, format) {
      let valuestring = numeral(value).format(format);
      return valuestring.replace("NaN", "0");
    },
    fy(fy) {
      let fy_temp = fy.toString().slice(-2)
      return "FY"+ (fy_temp-1) + fy_temp
    }
  },
  filters: {
    score(value) {
      return numeral(value).format("0,0.[0]");
    },
    code(value) {
      return value.toUpperCase();
    },
    true_or_false(value) {
      return value ? "Yes" : "No";
    },
  },
  computed: {
    filter_string() {
      let filter_string = {
        text: this.filter,
        filter1: this.filter1,
        filter2: this.filter2,
        filter3: this.filter3,
      };
      return filter_string;
    },

  },
  watch: {
    select_all() {
      console.log(this.select_all);
      if (this.select_all) {
        this.$refs.selectableTable.selectAllRows();
      } else {
        this.$refs.selectableTable.clearSelected();
      }
    },
    selected() {
      if (this.selected.length == 0) {
        this.select_all = false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.b-icon {
  margin-right: 0.3em;
}

.cell-head {
  font-size: 75%;
}
</style>
