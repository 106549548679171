<template>
  <b-button
    @click="downloadCsv"
    variant="primary"
    class="btn btn-sgs-dark-green"
  >
    Export
    <b-icon-download shift-h="3px" scale=".9" shift-v="1px" />
  </b-button>
</template>

<script>
import { parse } from "json2csv";

export default {
  name: "ExportData",
  components: {},
  props: {
    field: {
      type: Array,
      default: () => [],
      validator: (value) =>
        value.every(
          (field) =>
            typeof field.label === "string" && typeof field.key === "string"
        ),
    },
    data: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      default: "data.csv",
    },
    skip: {
      type: Array,
      default: () => ["id"],
    },
  },
  methods: {
    downloadCsv() {
      // Define the CSV fields based on the props
      let csvFields;

      // Define the CSV fields based on the props or data keys
      if (this.field.length > 0) {
        csvFields = this.field
          .filter((field) => !this.skip.includes(field.key))
          .map((field) => ({
            label: field.label,
            value: field.key,
          }));
      } else if (this.data.length > 0) {
        csvFields = Object.keys(this.data[0])
          .filter((key) => !this.skip.includes(key))
          .map((key) => ({
            label: key,
            value: key,
          }));
      } else {
        console.warn("No data available to create CSV.");
        return;
      }
      // Convert JSON to CSV using the defined fields
      const csv = parse(this.data, { fields: csvFields });

      // Create a Blob from the CSV data
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

      // Create a link element and simulate a click to trigger the download
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", this.name);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
  mounted() {
    if (typeof this.data == "string") {
      this.data = JSON.parse(this.data);
    }
  },
};
</script>

<style scoped></style>
