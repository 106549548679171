<template>
  <div>
    <b-card>
      <template v-slot:header>
        <b-card-title>Grant and Facilitation
        </b-card-title>
      </template>
      <b-row v-if="object == 'scenario'">
        <b-col class="text-center" v-if="(form.cost_type == 'Grant') | (form.cost_type == 'Both')">
          <b-card-title>{{ form.grant_total | currency }} </b-card-title>
          <b-card-sub-title>Total Grant</b-card-sub-title>
        </b-col>
        <b-col class="text-center" v-if="(form.cost_type == 'Facilitation') | (form.cost_type == 'Both')">
          <b-card-title>{{ form.hr_day }}</b-card-title>
          <b-card-sub-title>Facilitation days</b-card-sub-title>
        </b-col>
        <b-col cols="6">
          <ul style="list-style-type: none; padding-left: 0;">
            <li v-if="form.grant_required">
              <b-icon-check-circle-fill variant="sgs-green"/>
              Required to secure the project
            </li>
            <li v-else>
              <b-icon-dash-circle-fill variant="sgs-red"/>
              Not required to secure the project
            </li>
            <li v-if="form.grant_secure">
              <b-icon-check-circle-fill variant="sgs-green"/>
              Will definitely secure the project
            </li>
            <li v-else>
              <b-icon-dash-circle-fill variant="sgs-red"/>
              Uncertain if it will secure the project
            </li>
            <li v-if="form.expand_scope == 'Yes'">
              <b-icon-check-circle-fill variant="sgs-green"/>
              Will expand project scope
            </li>
            <li v-else>
              <b-icon-dash-circle-fill variant="sgs-red"/>
              Will not expand project scope
            </li>
            <li v-if="form.forward_timeline == 'Yes'">
              <b-icon-check-circle-fill variant="sgs-green"/>
              Will bring forward project timeline
            </li>
            <li v-else>
              <b-icon-dash-circle-fill variant="sgs-red"/>
              Will not bring forward project timeline
            </li>
          </ul>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
var numeral = require("numeral");

export default {
  name: "GrantCard",
  props: ["form", "editable", "object"],
  methods: {},
  filters: {
    code(value) {
      return value.toUpperCase();
    },
    true_or_false(value) {
      return value ? "Yes" : "No";
    },
    score(value) {
      return numeral(value).format("0,0.[0]");
    },
    number(value) {
      return numeral(value).format("0,0");
    },
    currency(value) {
      return numeral(value).format("$0,0");
    },
    global(value) {
      return value == "None of the above" ? "Not in global top 50" : value;
    },
    other_benefits(value) {
      return [null, "", " "].includes(value) ? "None described" : value;
    },
    text(value) {
      return [null, "", " "].includes(value) ? " - " : value;
    },
    desc(value) {
      return [null, "", " "].includes(value)
        ? "No supporting information provided"
        : value;
    },
    brand_filter(value) {
      return [null, "", " "].includes(value)
        ? "No information supporting brand recognition"
        : value;
    },
    market_filter(value) {
      return [null, "", " "].includes(value)
        ? "No information supporting global market position"
        : value;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.item {
  padding-left: 0.25em;
  padding-right: 0.25em;
  padding-bottom: 0.5em;
}

.card-text {
  font-size: 9pt;
  color: #6c757d;
  padding-top: 0.2em;
}

.card-subtitle {
  font-size: 11pt;
}

.card-title {
  font-size: 16pt;
}

li {
  font-size: 0.85em;
}

@media print {
  .card-body {
    padding-top: 0.5em;
    padding-bottom: 0.25em;
  }

  ul {
    margin-bottom: 0;
  }

  li {
    font-size: 0.85em;
  }

  #edit_button {
    display: none;
  }
}
</style>
