<template>
  <div>
    <b-overlay :show="loading" rounded="sm" no-wrap />
    <b-card no-body>
      <b-tabs
        pills
        card
        vertical
        justified
        v-model="tabIndex"
        nav-wrapper-class="col-2"
        active-nav-item-class="bg-sgs-red text-white"
      >
        <b-tab title="Qualifying Questions" title-link-class="text-sgs-red">
          <b-form @submit="next" @reset.prevent="reset_tab0">
            <InputText
              id="scenario_name"
              v-model="form.scenario_name"
              label="Scenario name:"
              placeholder="Enter a name for this scenario"
              list="list-scenario-name"
            />
            <b-datalist id="list-scenario-name">
              <option v-for="scenario in scenario_names" :key="scenario">
                {{ scenario }}
              </option>
            </b-datalist>

            <InputRadio
              id="radio-footloose"
              v-model="form.footloose"
              label="Is the project footloose?"
              help="A project is considered footloose if the proponent is considering locations outside Victoria."
              :options="yes_no"
            />

            <InputRadio
              id="radio-forword-timeline"
              v-model="form.forward_timeline"
              label="Will assistance bring forward project timeline?"
              help="Select <b>yes</b> if government assistance will lead to an earlier commencement or realisation of the project."
              :options="yes_no"
            />

            <InputRadio
              id="radio-expand-scope"
              v-model="form.expand_scope"
              label="Will assistance expand the project scope?"
              help="Select <b>yes</b> if government assistance will lead to an expanded project scope."
              :options="yes_no"
            />

            <InputSelect
              v-model="form.risk_assessment"
              label="Financial Risk assessment:"
              :options="risk_levels"
              placeholder="Select risk level"
            />

            <br />
            <b-row>
              <b-col cols="4" />
              <b-col>
                <b-button
                  @click="tabIndex--"
                  variant="secondary"
                  block
                  disabled
                >
                  Previous
                </b-button>
              </b-col>
              <b-col class="text-center">
                <b-button type="reset" variant="danger" block>Reset</b-button>
              </b-col>
              <b-col>
                <b-button
                  ref="tab0"
                  type="submit"
                  variant="primary"
                  block
                  v-if="!form.threshold_qualification"
                >
                  Next
                </b-button>
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  v-if="form.threshold_qualification"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-tab>
        <b-tab
          :disabled="form.threshold_qualification"
          title-link-class="text-sgs-red"
        >
          <template v-slot:title> Sector, Location, and Capabilities </template>
          <b-form @submit="next" @reset.prevent="reset_tab2">
            <InputSelect
              id="select-sector"
              v-model="form.sector"
              label="Sector:"
              :options="sector_data"
              placeholder="Select sector"
              help="Select the sector within which the project will primarily operate."
            />

            <InputSelect
              id="select-location"
              v-model="form.location_detailed"
              label="Location:"
              :options="location_data"
              placeholder="Select location"
              help="
              Priority metropolitan precincts are defined as:
              <ul>
                <li>Parkville</li>
                <li>Monash (Clayton)</li>
                <li>Arden</li>
                <li>Fishermans Bend</li>
              </ul>"
            />

            <InputSelect
              id="select-capability"
              v-model="form.capability"
              label="Does the project provide or contribute to any of the following capabilities?"
              help="Select one or more (hold <i>Ctrl</i> and <i>Click</i>) selections based on the capabilities of Victoria which will be enhanced by the project. For example, 'Access to finance (venture capital)' should be selected if a project increases the presence or accessibility of VC finance within Victoria."
              :options="capabilities"
              multiple
              :select_size="capabilities.length"
            />

            <InputTextArea
              id="text-area-capability"
              v-if="form.capability.length > 0"
              v-model="form.capability_text"
              placeholder="Please provide supporting information/explanation"
            />

            <br />
            <b-row>
              <b-col cols="4" />
              <b-col>
                <b-button @click="previous" variant="secondary" block
                  >Previous</b-button
                >
              </b-col>
              <b-col class="text-center">
                <b-button type="reset" variant="danger" block>Reset</b-button>
              </b-col>
              <b-col>
                <b-button ref="tab1" type="submit" variant="primary" block
                  >Next</b-button
                >
                <!-- <b-button type="submit" variant="primary" disabled>Submit</b-button> -->
              </b-col>
            </b-row>
          </b-form>
        </b-tab>

        <b-tab
          title="Economic Outcomes"
          :disabled="form.threshold_qualification"
          title-link-class="text-sgs-red"
        >
          <b-form @submit="next" @reset.prevent="reset_tab2">
            <h5>Capital Expenditure</h5>
            <InputRadio
              id="radio-capex-timing"
              v-model="form.capex_timing"
              label="Timing:"
              :options="known"
              help="Is the capital expenditure known on a yearly basis?"
            />

            <DynamicTableV2
              ref="capex_table"
              object="capex-table"
              label="Capital Expenditure"
              v-if="form.capex_timing == 'Known'"
              :items="form.capex_detail"
              :fields="capex_details_fields"
              @change="calculate_capex"
            />

            <InputCurrency
              v-else
              id="input-capital-expenditure"
              v-model="form.capex"
              label="Capital Expenditure"
              help="The amount of money the investor will spend to purchase fixed assets."
            />

            <InputRadio
              id="radio-construction-share-known"
              v-model="form.construction_share_known"
              label="Do you know how much is allocated for construction?"
              :options="known"
              @change="construction_share_known_changed"
            />

            <InputRange
              id="input-capex-construction-share"
              v-if="form.construction_share_known == 'Known'"
              v-model="form.capex_construction_share"
              label="How much is allocated for construction?"
              help="What share of capital expenditure is spent on construction (as opposed to machinery and equipment)?"
              :min="0"
              :max="100"
              unit="%"
            />

            <b-alert show variant="info">
              <h4 class="alert-heading">Known Innovative Expenditure</h4>
              <p>
                Only enter values if innovation expenditure is identifiable,
                discrete, and quantifiable
              </p>
              <p>
                Innovative capital expenditure is spending on scientific,
                technological, organisational, financial and commercial steps
                which actually, or are intended to, lead to the implementation
                of technologically new or improved products or processes.
              </p>
            </b-alert>

            <InputRadio
              id="radio-innovative-exp-known"
              v-model="form.innovative_exp_known"
              label="Is innovative expenditure known?"
              :options="known"
            />
            <div v-if="form.innovative_exp_known == 'Known'">
              <InputCurrency
                id="input-innovative-capex"
                v-model="form.innovative_capex"
                :required="false"
                label="Innovative Capital Expenditure"
                help="The amount of money the investor will spend towards assets to support innovation (ie. laboratory or R&D centre)."
              />

              <InputCurrency
                id="input-innovative-opex"
                v-model="form.innovative_opex"
                :required="false"
                label="Innovative Operational Expenditure"
                help="The amount of money the investor will spend towards innovative activities (ie. research project or feasibility study)."
              />
            </div>

            <br />

            <h5>Export</h5>
            <InputRadio
              id="radio-export-value-info"
              v-model="form.available_annual_export_value"
              label="Export value info:"
              :options="annual_export_values"
              help="What level of detail is known about the export characteristics of he project? <br/>
              Broad Ranges lets you pick from a list of broad export value ranges. Detailed estimate will let you input the export values by year."
            />

            <DynamicTableV2
              ref="export_table"
              object="export-table"
              label="Export value"
              v-if="form.available_annual_export_value == 'Detailed estimate'"
              :items="form.export_value_detail"
              :fields="export_details_fields"
              @change="calculate_export"
            />

            <InputSelect
              id="select-export-broad-range"
              v-if="form.available_annual_export_value == 'Broad ranges'"
              v-model="form.export_value_range"
              label="Export value:"
              :options="export_value_data"
              placeholder="Select an export value range"
            />

            <InputSelect
              id="select-export-diversity"
              v-model="form.export_diversity"
              label="Export diversification:"
              :options="export_diversities_data"
              help="Will the project diversify Victoria's exports (e.g. is it a product which Victoria currently does not export)?"
            />

            <InputSelect
              id="select-statewide-imports"
              v-model="form.state_import"
              label="Will the project alter total statewide imports?"
              :options="state_import_data"
              help="Will the project result in a change to Victoria's imports? This may be a reduction through caused by providing local supply of a good/service, or an increase caused by dependence on imports as inputs."
            />

            <br />
            <h5>Data Quality</h5>
            <InputSelect
              id="select-economic-data-quality"
              v-model="form.economic_data_quality_indicator"
              label="Data quality indicator:"
              :options="economic_data_quality_data"
              placeholder="Select data quality"
            />

            <br />
            <b-row>
              <b-col cols="4" />
              <b-col>
                <b-button @click="previous" variant="secondary" block
                  >Previous</b-button
                >
              </b-col>
              <b-col class="text-center">
                <b-button type="reset" variant="danger" block>Reset</b-button>
              </b-col>
              <b-col>
                <b-button ref="tab2" type="submit" variant="primary" block>
                  Next
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-tab>
        <b-tab
          :disabled="form.threshold_qualification"
          title-link-class="text-sgs-red"
        >
          <template v-slot:title>Employment Outcomes</template>
          <b-form @submit="next" @reset.prevent="reset_tab3">
            <h5>Information availability</h5>

            <InputRadio
              id="radio-known-skill-level"
              v-model="form.skill_known"
              label="Occupation:"
              :options="known"
              help="Are the occupations for the jobs created by this project known?"
            />

            <InputRadio
              id="radio-known-avg-salary"
              :disabled="form.skill_known == 'Known'"
              v-model="form.available_ave_annual_salary"
              label="Salary:"
              :options="known"
              help="Are the salaries for the jobs created by this project known?"
            />

            <InputRadio
              id="radio-jobs-timing"
              v-model="form.jobs_timing"
              label="Timing:"
              :options="known"
              help="Are the estimated direct jobs created by this project known for each year?"
            />

            <h5>
              Jobs
              <b-icon-exclamation-circle
                variant="sgs-red"
                v-b-tooltip.hover.top="'Required field'"
              />
            </h5>
            <InputNumber
              v-if="
                form.jobs_timing != 'Known' &&
                  form.available_ave_annual_salary != 'Known' &&
                  form.skill_known != 'Known'
              "
              id="direct-employment-number"
              v-model="form.direct_employment"
              label="Direct employment:"
              help="Full-time equivalent (FTE)"
              unit="jobs"
              placeholder="Enter amount of jobs"
            />

            <b-alert show variant="info" v-if="form.jobs_timing == 'Known'">
              <h4 class="alert-heading">Jobs timing assumptions</h4>
              <p>
                The figure entered in the latest year is assumed to be
                employment when the project is fully operational. This figure is
                applied across the remaining evaluation horizon.
              </p>
              <p>
                Please input the <b>total jobs each year</b>, not additional
                jobs in each year
              </p>
            </b-alert>

            <DynamicTableV3
              ref="employment_table"
              object="employment-details-table"
              v-if="
                form.jobs_timing == 'Known' ||
                  form.available_ave_annual_salary == 'Known'
              "
              :items="form.employment_detail.all"
              :fields="employment_details_fields"
              :hidden="employment_details_fields_hidden"
              @change="calculate_jobs"
            />

            <b-datalist id="list-occupation">
              <option v-for="occupation in occupations" :key="occupation">
                {{ occupation.label }}
              </option>
            </b-datalist>

            <br />
            <b-row>
              <b-col cols="4" />
              <b-col>
                <b-button @click="previous" variant="secondary" block
                  >Previous</b-button
                >
              </b-col>
              <b-col class="text-center">
                <b-button type="reset" variant="danger" block>Reset</b-button>
              </b-col>
              <b-col>
                <b-button ref="tab3" type="submit" variant="primary" block
                  >Next</b-button
                >
              </b-col>
            </b-row>
          </b-form>
        </b-tab>
        <b-tab
          :disabled="form.threshold_qualification"
          title-link-class="text-sgs-red"
        >
          <template v-slot:title>Brand & Innovation</template>
          <b-form @submit="next" @reset.prevent="reset_tab4">
            <h5>Brand</h5>
            <InputSelect
              id="select-brand-recognition"
              v-model="form.brand_recognition"
              label="How recognisable is the brand?"
              :options="brand_data"
              help_title="Brand recognition"
              help="
              Please select the most applicable option and provide supporting information. Examples include:
              <ul>
                <li>Household name: Didi or Pfizer</li>
                <li>Highly regarded within the industry: Softbank or Illumina</li>
              </ul>
              "
            >
              <!-- <div style="margin: 0.7em" /> -->
              <InputTextArea
                id="select-brand-text"
                cols="0"
                rows="3"
                v-model="form.brand_text"
                placeholder="Please provide supporting information/explanation"
              />
            </InputSelect>

            <InputSelect
              id="select-hq-status"
              v-model="form.hq_status"
              label="What is the HQ status of the project?"
              :options="hq_statuses"
              help="Does the project involve a headquarters in Victoria, and if so, what is it's jurisdiction?"
            />
            <InputSelect
              id="select-global-market-share"
              v-model="form.global_market_share"
              label="What is the proponent’s global market share in the sector of investment?"
              :options="global_market_shares"
              help_title="Global market share"
              help="
              <p>Please select the most appropriate option and provide supporting information or justification for this choice. </p>
              Examples to guide the interpretation of sector include:
              <ul>
                <li>Illumina’s position within the biotechnology sector</li>
                <li>Pfizer’s position within the pharmaceutical sector</li>
                <li>Softbank’s position within the technology or venture capital sectors</li>
              </ul>
              "
            >
              <!-- <div style="margin: 0.7em" /> -->
              <InputTextArea
                id="text-area-capability"
                cols="0"
                rows="3"
                v-model="form.global_market_share_text"
                placeholder="Please provide supporting information/explanation"
              />
            </InputSelect>

            <h5>Innovation</h5>

            <InputSelect
              id="select-rnd"
              v-model="form.rd_extent"
              label="Describe the operations and R&D of the project:"
              :options="rd_extents"
            />

            <InputSelect
              id="select-innovative-activity"
              v-model="form.innovative_activity"
              label="Does the project engage in any of the following innovative activities?"
              help_title="Innovative ‘to the business’ activity"
              help="This is activity intended to develop a new product, process or service, with the aim of increasing capability, improving efficiency, effectiveness or competitive advantage of the organisation."
              :options="innovative_activities"
              multiple
              select-size="5"
            />

            <InputRadio
              id="radio-unique-level"
              v-model="form.unique_level"
              label="Does the project provide Victoria with a unique product (currently unavailable) or service?"
              :options="yes_no"
            >
              <InputTextArea
                id="select-unique-level-text"
                cols="0"
                rows="3"
                v-model="form.unique_level_text"
                placeholder="Please provide justification"
              />
            </InputRadio>

            <InputRadio
              id="radio-productivity"
              v-model="form.productivity"
              label="Does the project introduce a more productive process or capability to the Victorian economy (state-significant)?"
              :options="yes_no"
              v-if="
                form.rd_extent ==
                  'Operations include novel and/or other innovative activities'
              "
              help="The answer to this question will not be used for calculating innovation expenditure unless in exceptional circumstances"
            >
              <InputTextArea
                id="select-productivity-text"
                cols="0"
                rows="3"
                v-model="form.productivity_text"
                placeholder="Please provide justification"
              />
            </InputRadio>

            <br />
            <b-row>
              <b-col cols="4" />
              <b-col>
                <b-button @click="previous" variant="secondary" block>
                  Previous
                </b-button>
              </b-col>
              <b-col class="text-center">
                <b-button type="reset" variant="danger" block>Reset</b-button>
              </b-col>
              <b-col>
                <b-button ref="tab4" type="submit" variant="primary" block>
                  Next
                </b-button>
                <!-- <b-button type="submit" variant="primary" disabled>Submit</b-button> -->
              </b-col>
            </b-row>
          </b-form>
        </b-tab>

        <b-tab
          title="Cost Parameters"
          :disabled="form.threshold_qualification"
          title-link-class="text-sgs-red"
        >
          <b-form @submit="next" @reset.prevent="reset_tab5">
            <InputRadio
              id="radio-cost-type"
              v-model="form.cost_type"
              label="Cost Type:"
              :options="cost_types"
              help="<b>Grants</b> are a form of a monetary assistance. <b>Facilitation</b> comes as a service-based support"
            />

            <InputRadio
              id="radio-cost-timing"
              v-model="form.timing"
              label="Timing:"
              :options="known"
              help="Is the cost to government known on a yearly basis?"
            />

            <DynamicTableV2
              ref="cost_timing_table"
              object="cost-timing-table"
              label="Cost"
              v-if="form.timing == 'Known'"
              :items="form.cost_details"
              :fields="cost_details_fields"
              @change="calculate_cost"
            />

            <InputCurrency
              v-if="
                form.timing != 'Known' &&
                  ['Grant', 'Both'].includes(form.cost_type)
              "
              id="grant-total"
              v-model="form.grant_total"
              label="Grant Total:"
              help="Total grant requested for this project"
            />

            <InputNumber
              v-if="
                form.timing != 'Known' &&
                  ['Facilitation', 'Both'].includes(form.cost_type)
              "
              id="hr-days"
              v-model="form.human_resource_days"
              label="Human resource days:"
              help="Total number of days to allocate for facilitating the project"
              unit="days"
            />

            <InputRadio
              id="radio-grant-required"
              v-model="form.grant_required"
              label="Is the grant/facilitation required to secure the project?"
              :options="options"
            />
            <InputRadio
              id="radio-grant-security"
              v-model="form.grant_security"
              label="Will grant/facilitation secure the project?"
              :options="certainty"
            />

            <InputSelect
              id="select-cost-data-quality"
              v-model="form.cost_data_quality_indicator"
              label="Data quality indicator:"
              :options="cost_data_quality_data"
              placeholder="Select data quality"
            />

            <br />
            <b-row>
              <b-col cols="4" />
              <b-col>
                <b-button @click="previous" variant="secondary" block
                  >Previous</b-button
                >
              </b-col>
              <b-col class="text-center">
                <b-button type="reset" variant="danger" block>Reset</b-button>
              </b-col>
              <b-col>
                <b-button ref="tab5" type="submit" variant="primary" block>
                  Next
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-tab>

        <b-tab
          :disabled="form.threshold_qualification"
          title-link-class="text-sgs-red"
          title="Other Benefits"
        >
          <b-form @submit="onSubmit" @reset.prevent="reset_tab7">
            <InputTextArea
              id="text-environment"
              v-model="form.environment_benefits"
              label="Other benefits"
              rows="12"
              placeholder="Describe any other benefits of the project"
            />
            <br />
            <b-row>
              <b-col cols="4" />
              <b-col>
                <b-button @click="previous" variant="secondary" block>
                  Previous
                </b-button>
              </b-col>
              <b-col class="text-center">
                <b-button type="reset" variant="danger" block>Reset</b-button>
              </b-col>
              <b-col>
                <b-button type="submit" variant="primary" block>
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-tab>
        <!-- End tabs -->
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
// Import form components
// import DynamicTable from "@/components/DynamicTable.vue";
import DynamicTableV2 from "@/components/DynamicTableV2.vue";
import DynamicTableV3 from "@/components/DynamicTableV3.vue";
import InputCurrency from "@/components/form/InputCurrency.vue";
import InputNumber from "@/components/form/InputNumber.vue";
import InputRange from "@/components/form/InputRange.vue";
import InputSelect from "@/components/form/InputSelect.vue";
import InputRadio from "@/components/form/InputRadio.vue";
import InputTextArea from "@/components/form/InputTextArea.vue";
import InputText from "@/components/form/InputText.vue";
// import InputHeader from "@/components/form/InputHeader.vue";
// Import selection data
import risk_levels from "@/data/risk.json";
import hq_statuses from "@/data/hq.json";
import global_market_shares from "@/data/global_market_share.json";
import rd_extents from "@/data/r&d.json";
import innovative_activities from "@/data/innovative_activity.json";
import unique_levels from "@/data/unique.json";
import sector_data from "@/data/sector.json";
import location_data from "@/data/location.json";
import cost_data_quality_data from "@/data/cost_data_quality.json";
import economic_data_quality_data from "@/data/economic_data_quality.json";
import export_value_data from "@/data/export_value.json";
import export_diversities_data from "@/data/export_diversities.json";
import occupations from "@/data/occupation.json";
import capabilities from "@/data/capability.json";
import brand_data from "@/data/brand.json";
import patent_data from "@/data/patent.json";
import state_import_data from "@/data/state_import.json";
import hays from "@/data/hays.json";

var _ = require("lodash");

export default {
  name: "OpportunityScenarioForm",
  props: ["data"],
  components: {
    // DynamicTable,
    DynamicTableV2,
    DynamicTableV3,
    InputCurrency,
    InputNumber,
    InputRange,
    InputSelect,
    InputRadio,
    InputTextArea,
    InputText,
    // InputHeader,
  },
  data() {
    return {
      loading: false,
      tabIndex: 0,
      form: {
        // Qualifying Questions
        scenario_name: "Expected outcome",
        footloose: "No",
        forward_timeline: "No",
        expand_scope: "No",
        threshold_qualification: false,
        disqualification_reason: "",
        risk_assessment: "Unknown",
        // Strategic Alignment
        sector: null,
        location_broad: null,
        location_detailed: "Uncertain",
        intl_flights: false,
        intl_tourism: false,
        capability: [], // not required
        capability_text: null,
        occupation: [], // not required
        // Brand & Innovation
        brand_recognition: null, //required
        brand_text: null, //required
        hq_status: null, //required
        global_market_share: null, //required
        global_market_text: null,
        rd_extent: null, //required
        unique_level: "No", //required
        unique_level_text: "",
        patent: "None", //required
        innovation_new: "No", //required
        innovative_activity: [],
        innovation_business: "No",
        productivity: "No", //required
        productivity_text: "",
        innovative_capex: null,
        innovative_opex: null,
        innovative_exp_known: "Uncertain",
        // Cost Estimates
        cost_type: "Both",
        timing: "Uncertain",
        grant_required: false,
        grant_security: "Uncertain",
        grant_total: null,
        human_resource_days: null,
        cost_data_quality_indicator: null,
        cost_details: [],
        // Economic Outcomes - Data Quality
        opp_scenario_type: "General",
        capex_timing: "Uncertain",
        economic_data_quality_indicator: null,
        // Economic Outcomes - Measures
        capex: null,
        construction_share_known: "Uncertain",
        capex_construction_share: 50,
        capex_detail: [],
        available_annual_export_value: "Broad ranges",
        export_value_detail: [],
        export_value_range: null,
        export_diversity: "NA",
        state_import: "Unknown",

        // Job Outcomes
        skill_known: "Uncertain",
        available_ave_annual_salary: "Uncertain",
        jobs_timing: "Uncertain",
        direct_employment: null,
        ave_annual_salary: null,
        employment_detail: {
          all: [],
        },
        // Social & Environmental Outcomes
        environment_benefits: null,
        social_benefits: null,
      },
      scenario_names: ["Expected outcome", "Alternate scenario"],
      options: [
        { value: false, text: "No" },
        { value: true, text: "Yes" },
      ],
      yes_no: ["Yes", "No"],
      certainty: ["Yes", "Uncertain"],
      known: ["Known", "Uncertain"],
      risk_levels,
      sector_data,
      locations_broad: [
        { text: "Not applicable", value: "NA" },
        "Greater Melbourne",
        "Rest of Victoria",
      ],
      location_data,
      capabilities,
      brand_data,
      patent_data,
      occupations,
      hays,
      hq_statuses,
      global_market_shares,
      rd_extents,
      innovative_activities,
      unique_levels,
      state_import_data,
      cost_types: ["Grant", "Facilitation", "Both"],
      cost_data_quality_data,

      annual_export_values: [
        "Broad ranges",
        "Detailed estimate",
        { text: "Uncertain", value: "Default value" },
      ],
      data_quality_indicators: [
        "Judgement or basic information",
        "Moderate information",
        "Detailed analysis",
      ],
      economic_data_quality_data,
      export_value_data,
      export_diversities_data,

      // default fields
      // cost_details_fields: ["year", "grant", "hr_days", "index"],
      // capex_details_fields: ["year", "capex_value", "index"],
      cost_details_fields_default: [
        {
          key: "year",
          label: "Year",
          format: "0",
          min: "2019",
          max: "2099",
          type: "number",
          placeholder: "Enter year (ie. 2022)",
          required: true,
          sortable: false,
        },
        {
          key: "hr_days",
          label: "Human resource days",
          format: "0,0",
          type: "number",
          unit: "days",
          placeholder: "Enter number of facilitation days",
          required: true,
          sortable: false,
        },
        {
          key: "grant",
          label: "Grant",
          format: "$0,0",
          type: "currency",
          required: true,
          sortable: false,
        },
        {
          key: "index",
          label: "Actions",
          type: "hidden",
        },
      ],
      export_details_fields: [
        {
          key: "year",
          label: "Year",
          format: "0",
          min: "2019",
          max: "2099",
          type: "number",
          placeholder: "Enter year (ie. 2022)",
          required: true,
          sortable: false,
        },
        {
          key: "export_value",
          label: "Export value",
          format: "$0,0",
          type: "currency",
          required: true,
          sortable: false,
        },
        {
          key: "index",
          label: "Actions",
          type: "hidden",
        },
      ],
      capex_details_fields: [
        {
          key: "year",
          label: "Year",
          format: "0",
          min: "2019",
          max: "2099",
          type: "number",
          placeholder: "Enter year (ie. 2022)",
          required: true,
          sortable: false,
        },
        {
          key: "capex_value",
          label: "Capital Expenditure",
          format: "$0,0",
          type: "currency",
          required: true,
          sortable: false,
        },
        {
          key: "index",
          label: "Actions",
          type: "hidden",
        },
      ],
      employment_details_fields_default: [
        {
          key: "skill",
          label: "Employment Category",
          type: "selection",
          placeholder: "Select category",
          required: true,
          sortable: false,
          text_field: "value",
          list: occupations,
          change: () => {}, // function
        },
        {
          key: "occupation",
          label: "Occupation",
          placeholder: "Enter job title",
          type: "text",
          required: false,
          sortable: false,
          list: hays,
          change: () => {}, // function
        },
        {
          key: "year",
          label: "Year",
          format: "0",
          min: "2019",
          max: "2099",
          type: "number",
          placeholder: "Enter year (ie. 2022)",
          help_title: "Year",
          help: "This field requires an actual year (2022, 2023, 2024, etc.)",
          required: true,
          sortable: false,
        },
        {
          key: "direct_employment_fte",
          label: "Annual jobs",
          format: "0,0",
          type: "number",
          help_title: "Annual jobs (FTE)",
          help: "Annual full-time equivalent jobs once fully operational",
          unit: "FTE",
          placeholder: "Enter number of jobs",
          required: true,
          sortable: false,
        },
        {
          key: "ave_salary",
          label: "Average Salary",
          format: "$0,0",
          type: "currency",
          help_title: "Default average annual salary",
          help:
            "Average annual salary are marked in yellow if they match the default salary for selected role.",
          tdClass: this.check_default,
          required: true,
          sortable: false,
        },
        {
          key: "index",
          label: "Actions",
          type: "hidden",
        },
      ],
      // export_details_fields: ["year", "export_value", "index"],

      show: true,
      isVisible: false,
    };
  },
  methods: {
    next(event) {
      if (!this.form.threshold_qualification) {
        event.preventDefault();
        if (this.validate(this.tabIndex)) {
          this.tabIndex++;
        }
      } else {
        this.submitDisqualified(event);
      }
    },
    previous() {
      this.tabIndex--;
    },
    validate(index) {
      let msgBoxConfig = {
        title: "Required information",
        size: "sm",
        centered: true,
      };
      if (index == 5) {
        if (this.form.timing == "Known") {
          if (this.form.cost_details.length == 0) {
            this.$bvModal.msgBoxOk(
              "Please fill in the cost timing table.",
              msgBoxConfig
            );
            return false;
          }
        }
      } else if (index == 2) {
        if (this.form.capex_timing == "Known") {
          if (this.form.capex_detail.length == 0) {
            this.$bvModal.msgBoxOk(
              "Please fill in the capital expenditure timing table.",
              msgBoxConfig
            );
            return false;
          }
        }
        if (this.form.available_annual_export_value == "Detailed estimate") {
          if (this.form.export_value_detail.length == 0) {
            this.$bvModal.msgBoxOk(
              "Please fill in the export timing table.",
              msgBoxConfig
            );
            return false;
          }
        }
      } else if (index == 3) {
        if (
          this.form.jobs_timing == "Known" ||
          this.form.skill_known == "Known"
        ) {
          if (this.form.employment_detail.all.length == 0) {
            this.$bvModal.msgBoxOk(
              "Please fill in the jobs table.",
              msgBoxConfig
            );
            return false;
          }
        }
      }
      return true;
    },
    submitDisqualified(event) {
      event.preventDefault();
      this.$emit("submitDisqualified", this.form);
    },
    validateAllTabs() {
      let msgBoxConfig = {
        title: "Required information",
        size: "sm",
        centered: true,
      };
      // this goes back to each tab
      // and clicks the "next" button
      // to trigger validation
      this.tabIndex = 0;
      for (var i = 0; i <= 6; i++) {
        if (this.tabIndex == i) {
          if (i == 6) {
            return true;
          }
          this.$refs["tab" + i].click();
        } else {
          // this is a workaround since chrome doesn't
          // focus on the required field you need to fill in.
          this.$bvModal.msgBoxOk(
            "Please fill up the required fields in this page.",
            msgBoxConfig
          );
          return false;
        }
      }
      return true;
    },
    onSubmit(event) {
      event.preventDefault();
      if (this.validateAllTabs()) {
        this.$emit("onSubmit", this.form);
      }
    },
    reset_tab0() {
      this.form.scenario_name = null;
      this.form.footloose = "NA";
      this.form.expand_scope = "No";
      this.form.forward_timeline = "No";
      this.form.threshold_qualification = false;
      this.form.disqualification_reason = "";
      this.form.risk_assessment = null;
    },
    reset_tab1() {
      this.form.sector = null;
      this.form.location_broad = null;
      this.form.location_detailed = "Uncertain";
      this.form.intl_flights = false;
      this.form.intl_tourism = false;
      this.form.capability = [];
      this.form.capability_text = null;
      this.form.occupation = [];
    },
    reset_tab2() {
      this.form.capex_timing = "Uncertain";
      this.form.capex = null;
      this.form.construction_share_known = "Uncertain";
      this.form.capex_construction_share = 50;
      this.form.capex_detail = [];
      this.form.available_annual_export_value = "Broad ranges";
      this.form.export_value_range = null;
      this.form.export_diversity = "NA";
      this.form.export_value_detail = [];
      this.form.state_import = "Unknown";
      this.form.economic_data_quality_indicator = null;
    },
    reset_tab3() {
      this.form.skill_known = "Uncertain";
      this.form.available_ave_annual_salary = "Uncertain";
      this.form.jobs_timing = "Uncertain";
      this.form.direct_employment = null;
      this.form.ave_annual_salary = null;
      this.form.employment_detail.all = [];
    },
    reset_tab4() {
      this.form.hq_status = null;
      this.form.global_market_share = null;
      this.form.global_market_text = null;
      this.form.brand_recognition = null;
      this.form.brand_text = null;
      this.form.rd_extent = null;
      this.form.unique_level = null;
      this.form.unique_level_text = null;
      this.form.productivity = null;
      this.form.productivity_text = null;
      this.form.patent = "None";
      this.form.innovation_new = "No";
      this.form.innovative_activity = [];
      this.form.innovation_business = "No";
    },
    reset_tab5() {
      this.form.cost_type = "Both";
      this.form.grant_required = false;
      this.form.grant_security = "Uncertain";
      this.form.human_resource_days = null;
      this.form.grant_total = null;
      this.form.cost_details = [];
      this.form.timing = "Uncertain";
      this.form.cost_data_quality_indicator = null;
    },
    reset_tab7() {
      // this.form.social_benefits = null;
      this.form.environment_benefits = null;
    },
    calculate_cost() {
      this.form.grant_total = 0;
      this.form.human_resource_days = 0;
      for (let i = 0; i < this.form.cost_details.length; i++) {
        this.form.grant_total += parseFloat(this.form.cost_details[i].grant);
        this.form.human_resource_days += parseFloat(
          this.form.cost_details[i].hr_days
        );
      }
    },
    calculate_capex() {
      this.form.capex = 0;
      for (let i = 0; i < this.form.capex_detail.length; i++) {
        this.form.capex += parseFloat(this.form.capex_detail[i].capex_value);
      }
    },
    calculate_jobs() {
      this.form.direct_employment = 0;
      this.form.ave_annual_salary = 0;
      for (var i = 0; i < this.form.employment_detail.all.length; i++) {
        this.form.direct_employment += parseFloat(
          this.form.employment_detail.all[i].direct_employment_fte
        );
        this.form.ave_annual_salary +=
          parseFloat(this.form.employment_detail.all[i].ave_salary) *
          parseFloat(this.form.employment_detail.all[i].direct_employment_fte);
      }
      this.form.ave_annual_salary =
        this.form.ave_annual_salary / this.form.direct_employment;
    },
    calculate_export() {
      this.form.annual_export_value = 0;
      for (var i = 0; i < this.form.export_value_detail.length; i++) {
        this.form.annual_export_value += parseFloat(
          this.form.export_value_detail[i].export_value
        );
      }
      this.form.annual_export_value =
        this.form.annual_export_value / this.form.export_value_detail.length;
    },
    construction_share_known_changed() {
      if (this.form.construction_share_known == "Uncertain") {
        this.form.capex_construction_share = 50;
      }
    },
    apply_salary_default(new_item) {
      if (Object.keys(new_item).includes("ave_salary")) {
        if (new_item.ave_salary != null && new_item.ave_salary != "") {
          new_item.ave_salary = new_item.ave_salary
            .toString()
            .replace(/[,]/g, "");
        }
      }
      if (Object.keys(new_item).includes("occupation")) {
        let search = this.hays.filter(
          (obj) => obj.value == new_item.occupation
        );
        if (search.length >= 1) {
          if (
            new_item.ave_salary == null ||
            new_item.ave_salary == "" ||
            new_item.ave_salary == search[0].salary
          ) {
            new_item.ave_salary = search[0].salary;
          }
        }
      }
    },
    check_default(value, key, item) {
      let filters = this.splitLastOccurrence(item.occupation,"-")
      let search = this.hays.filter(
        (obj) => (obj.value == filters[0]) & 
                 (obj.location == filters[1])
      );
      if (search.length >= 1) {
        if (search[0].salary == value.toString().replace(/[,]/g, "")) {
          return "default_salary";
        }
      }
    },
    splitLastOccurrence(str, substring) {
      let lastIndex = str.lastIndexOf(substring);
      let before = str.slice(0, lastIndex).trim();
      let after = str.slice(lastIndex + 1).trim();
      return [before, after];
    }
  },
  computed: {
    employment_details_fields() {
      var fields = _.cloneDeep(this.employment_details_fields_default);
      let get_keys = () => {
        return fields.map(function(e) {
          return e.key;
        });
      };
      if (this.form.jobs_timing == "Uncertain") {
        fields.splice(get_keys().indexOf("year"), 1);
      }
      if (this.form.skill_known == "Uncertain") {
        fields.splice(get_keys().indexOf("skill"), 1);
        fields.splice(get_keys().indexOf("occupation"), 1);
      }
      if (this.form.available_ave_annual_salary == "Uncertain") {
        fields.splice(get_keys().indexOf("ave_salary"), 1);
      }
      return fields;
    },
    employment_details_fields_hidden() {
      var hidden = {};

      if (this.form.jobs_timing == "Uncertain") {
        hidden["year"] = 0;
      }
      if (this.form.skill_known == "Uncertain") {
        hidden["skill"] = "Unknown";
        hidden["occupation"] = "";
      }
      if (this.form.available_ave_annual_salary == "Uncertain") {
        hidden["ave_salary"] = 0;
      }
      return hidden;
    },
    cost_details_fields() {
      var fields = _.cloneDeep(this.cost_details_fields_default);
      let get_keys = () => {
        return fields.map(function(e) {
          return e.key;
        });
      };
      if (this.form.cost_type == "Grant") {
        fields.splice(get_keys().indexOf("hr_days"), 1);
      } else if (this.form.cost_type == "Facilitation") {
        fields.splice(get_keys().indexOf("grant"), 1);
      }
      return fields;
    },

  },
  watch: {
    "form.skill_known": function(val) {
      if (val == "Known") {
        this.form.available_ave_annual_salary = "Known";
      }
      // this.employment_info_changed();
    },
    // "form.available_ave_annual_salary": function () {
    //   this.employment_info_changed();
    // },
    // "form.jobs_timing": function () {
    //   this.employment_info_changed();
    // },
  },
  beforeMount() {
    if (this.data) {
      if (this.data.threshold_qualification) {
        this.form.scenario_name = this.data.scenario_name;
        this.form.footloose = this.data.footloose;
        this.form.expand_scope = this.data.expand_scope;
        this.form.forward_timeline = this.data.forward_timeline;
        this.form.threshold_qualification = this.data.threshold_qualification;
        this.form.disqualification_reason = this.data.disqualification_reason;
        this.form.risk_assessment = this.data.risk_assessment;
      } else {
        this.form = this.data;
        let arr = [];
        this.data.capability.split(",").forEach((element) => {
          let e = element.replace(/['"{}]+/g, "");
          arr.push(e);
        });
        this.form.capability = arr;

        arr = [];
        this.data.innovative_activity.split(",").forEach((element) => {
          let e = element.replace(/['"{}]+/g, "");
          arr.push(e);
        });
        this.form.innovative_activity = arr;
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "@/scss/_colours.scss";

.bi-blank {
  width: 0.5em;
}
a#_*_tab_button__.navlink.disabled.sgs-text-red {
  color: $gray-600 !important;
}
</style>

<style lang="scss">
@import "@/scss/_colours.scss";

.default_salary {
  color: $yellow !important;
}
</style>
