<template>
  <div>
    <PageTitle
      variant="sgs-red"
      title="Create"
      text="Fill in the form to create a new opporunity record."
    ></PageTitle>
    <b-overlay :show="loading" rounded="sm" no-wrap />
    <b-container v-if="loaded">
      <OpportunityForm
        @onSubmit="createOpportunity($event)"
        :companies="companies"
        :owners="owners"
      />
    </b-container>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import OpportunityForm from "@/components/OpportunityForm.vue";

export default {
  name: "CreateOpportunity",
  components: {
    OpportunityForm,
    PageTitle,
  },
  data() {
    return {
      loading: true,
      loaded: false,
      companies: [],
      owners: [],
    };
  },
  methods: {
    createOpportunity(event) {
      this.loading = true;
      // event.preventDefault();
      this.$store
        .dispatch("createOpportunity", {
          data: event,
        })
        .then((response) => {
          this.loading = false;
          this.$router.push({
            name: "view_opportunity",
            params: {
              id: response.data.public_id,
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.response.data);
        });
    },
  },
  created() {
    this.$store
      .dispatch("getCompanies")
      .then((response) => {
        this.companies = response.data.companies;
        this.owners = response.data.owners;
        this.loaded = true;
        this.loading = false;
      })
      .catch((error) => {
        this.serverError = error.response.data;
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
