<template>
  <div>
    <PageTitle
      variant="sgs-green"
      title="View"
      text="Here's a snapshot of your opportunity. <br/> You can create scenarios from this page."
    ></PageTitle>
    <b-overlay :show="!loaded" rounded="sm" no-wrap />
    <b-container v-if="loaded">
      <b-row>
        <b-col cols="5">
          <SummaryCard :editable="true" :form="form" object="opportunity" />
        </b-col>
        <b-col>
          <b-row>
            <b-col>
              <h3>Scenarios</h3>
            </b-col>
            <b-col style="text-align: right">
              <b-button
                size="md"
                variant="sgs-red"
                :href="`${$route.params.id}/scenario/create`"
              >
                New Scenario
                <b-icon-plus-circle-fill />
              </b-button>
              <br />
              <br />
            </b-col>
          </b-row>
          <Table
            :filter_toggle="false"
            :fields="fields"
            :items="items"
            object="scenario"
            @deleteObject="deleteScenario($event)"
            @assessScenario="assessScenario($event)"
            @selectClaimed="selectClaimed($event)"
            :fyear="this.form.fyear"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import SummaryCard from "@/components/SummaryCard.vue";
import Table from "@/components/Table.vue";

export default {
  components: {
    // Opportunity,
    SummaryCard,
    Table,
    PageTitle,
  },
  data() {
    return {
      loaded: false,
      form: {
        team: "",
        opportunity_name: "",
        type: null,
        company: "",
        country: null,
        opportunity_type: null,
        opportunity_owner: "",
        opportunity_code: "",
        description: "",
        stage: null,

        scenario_name: "",
        footloose: "",
        disqualification: "",
        risk_level: "",
      },
      items: [],
      fields: [
        { key: "claim", label: "", sortable: false },
        "scenario",
        // "footloose",
        // "disqualified",
        // "risk",
        { key: "id", label: "Actions", sortable: false },
        // { key: "del", label: "", sortable: false },
      ],
    };
  },
  created() {
    this.$store
      .dispatch("getOpportunity", {
        data: this.$route.params.id,
      })
      .then((response) => {
        this.form = response.data.opportunity;
        this.items = response.data.opportunity.scenarios;
        this.loaded = true;
      });
  },
  mounted() {},
  methods: {
    assessScenario(event) {
      // event.preventDefault();
      this.loaded = false;
      this.$store
        .dispatch("createAssessment", {
          report_year: event.report_year,
          id: event.selected_id,
        })
        .then((response) => {
          this.$router.push({
            name: "view_assessment",
            params: {
              id: response.data.public_id,
            },
          });
        })
        .catch((error) => {
          if (!error.response) {
            console.log("There was a problem in the server.");
          } else if (error.response.status === 500) {
            console.log("There was some error in the calculations.");
          }
          console.log(error.response.data);
          alert(error.response.data);
        })
        .finally(() => {
          this.loaded = true;
        });
    },
    deleteScenario(event) {
      // event.preventDefault();
      this.loaded = false;
      this.$store
        .dispatch("deleteScenario", {
          id: event.selected_id,
        })
        .then(() => {
          this.items.splice(
            this.items.findIndex((item) => item.id === event.selected_id),
            1
          );
        })
        .catch((error) => {
          if (!error.response) {
            console.log("There was a problem in the server.");
          }
          console.log(error.response.data);
          alert(error.response.data);
        })
        .finally(() => {
          this.loaded = true;
        });
    },
    selectClaimed(event) {
      // event.preventDefault();
      this.loaded = false;
      this.$store
        .dispatch("put", {
          url:
            "/opportunity/" +
            this.$route.params.id +
            "/prefer/" +
            event.selected_id,
          data: {
            fyear: event.fyear,
            is_complete: event.is_complete,
          },
        })
        .then(() => {
          let falseIndex = this.items.findIndex(
            (item) => item.preferred === true
          );
          if (falseIndex > -1) {
            this.items[falseIndex].preferred = false;
          }

          let index = this.items.findIndex(
            (item) => item.id === event.selected_id
          );
          this.items[index].preferred = true;

          if (event.is_complete == true) {
            this.form.preferred = "Complete";
          } else {
            this.form.preferred = null;
          }
        })
        .catch((error) => {
          if (!error.response) {
            console.log("There was a problem in the server.");
          }
          console.log(error.response.data);
          alert(error.response.data);
        })
        .finally(() => {
          this.loaded = true;
        });
    },
  },
};
</script>

<style scoped></style>
