<template>
  <div>
    <PageTitle
      variant="sgs-green"
      title="Edit"
      text="Here's the opportunity that you would like to edit. <br/> Submit this form to save your changes."
    ></PageTitle>
    <b-overlay :show="loading" rounded="sm" no-wrap />
    <b-container v-if="loaded">
      <OpportunityForm
        @onSubmit="editOpportunity($event)"
        :companies="companies"
        :data="form"
      />
    </b-container>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import OpportunityForm from "@/components/OpportunityForm.vue";

export default {
  name: "OpportunityEdit",
  components: {
    OpportunityForm,
    PageTitle,
  },
  data() {
    return {
      loading: true,
      loaded: false,
      companies: [],
      form: {
        team: null,
        opportunity_name: "",
        opportunity_code: "",
        opportunity_owner: "",
        type: null,
        company: "",
        country: null,
        opportunity_type: null,
        description: "",
        stage: null,
        is_complete: false,
      },
    };
  },
  methods: {
    editOpportunity(event) {
      this.loading = true;
      // event.preventDefault();
      this.$store
        .dispatch("editOpportunity", {
          data: event,
          id: this.$route.params.id,
        })
        .then((response) => {
          this.loading = false;
          this.$router.push({
            name: "view_opportunity",
            params: {
              id: response.data.public_id,
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          console.log(error.response.data);
        });
    },
  },
  created() {
    this.$store
      .dispatch("getOpportunity", {
        data: this.$route.params.id,
      })
      .then((response) => {
        this.form = response.data.opportunity;

        this.$store.dispatch("getCompanies").then((response) => {
          this.companies = response.data;
          this.loaded = true;
          this.loading = false;
        });
      })
      .catch((error) => {
        this.serverError = error.response.data;
      });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
