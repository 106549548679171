<script>
import { Line } from "vue-chartjs";

var numeral = require("numeral");

export default {
  extends: Line,
  props: ["chart_data"],
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Capital Expenditure",
            yAxisID: "left",
            borderWidth: 2,
            pointRadius: 3,
            pointHoverRadius: 4.5,
            backgroundColor: "rgba(228, 127, 0, 0.5)",
            pointBackgroundColor: "rgba(228, 127, 0, 1)",
            pointBorderColor: "rgba(228, 127, 0, 1)",
            fill: true,
            order: 1,
            data: [],
          },
          {
            label: "Operational Expenditure",
            yAxisID: "right",
            borderWidth: 2,
            pointRadius: 3,
            pointHoverRadius: 4.5,
            backgroundColor: "rgba(0, 151, 172, 0.2)",
            pointBackgroundColor: "rgba(0, 151, 172, 1)",
            pointBorderColor: "rgba(0, 151, 172, 1)",
            fill: true,
            order: 2,
            data: [],
          },
          // {
          //   label: "Gross State Product",
          //   borderWidth: 2,
          //   pointRadius: 3,
          //   pointHoverRadius: 4.5,
          //   backgroundColor: "rgba(118, 185, 0, 0.2)",
          //   pointBackgroundColor: "rgba(118, 185, 0, 1)",
          //   pointBorderColor: "rgba(118, 185, 0, 1)",
          //   data: [],
          // },
        ],
      },
      options: {
        title: {
          text: "Source of GSP Uplift",
          lineHeight: 1,
          padding: 0,
          fullWidth: false,
          display: true,
        },
        legend: {
          label: "Source of GSP Uplift",
          fullWidth: false,
          // position: "right",
          // align: "end",
          // display: false,
        },

        scales: {
          yAxes: [
            {
              id: "left",
              display: true,
              position: "left",
              gridLines: {
                display: false,
              },
              ticks: {
                callback: function (value) {
                  return numeral(value).format("$0.[0]a");
                },
                beginAtZero: true,
                maxTicksLimit: 10,
              },
              scaleLabel: {
                display: true,
                labelString: "GSP Uplift from Capital Expenditure",
              },
            },
            {
              id: "right",
              display: true,
              position: "right",
              gridLines: {
                display: false,
              },
              ticks: {
                callback: function (value) {
                  return numeral(value).format("$0.[0]a");
                },
                beginAtZero: true,
                maxTicksLimit: 10,
              },
              scaleLabel: {
                display: true,
                labelString: "GSP Uplift from Operational Expenditure",
              },
            },
          ],
          xAxes: [
            {
              stacked: true,
              display: true,
              ticks: {
                beginAtZero: true,
                stepSize: 1,
              },
              scaleLabel: {
                display: true,
                labelString: "Year",
              },
            },
          ],
        },
        tooltips: {
          custom: function (tooltip) {
            if (!tooltip) return;
            //disable displaying colour box
            tooltip.displayColors = false;
          },
          callbacks: {
            title: function (tooltipItem, data) {
              let gsp =
                data.datasets[0].data[tooltipItem[0].index] +
                data.datasets[1].data[tooltipItem[0].index];
              return [
                tooltipItem[0].xLabel,
                "Gross State Product: " + numeral(gsp).format("$0,0"),
              ];
            },
            label: function (tooltipItem, data) {
              return [
                // "Aggregate score: " +
                data.datasets[tooltipItem.datasetIndex].label +
                  ": " +
                  numeral(tooltipItem.yLabel).format("$0,0"),
                //   ", Cost bracket: " +
                //   tooltipItem.yLabel,
              ];
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.chartData.labels = this.chart_data.map((object) => object.year);
    this.chartData.datasets[0].data = this.chart_data.map(
      (object) => object.gsp_capex
    );
    this.chartData.datasets[1].data = this.chart_data.map(
      (object) => object.gsp_opex
    );
    // this.chartData.datasets[2].data = this.chart_data.map(
    //   (object) => object.gsp_total
    // );
    this.renderChart(this.chartData, this.options);
  },
  filters: {
    currency(value) {
      return numeral(value).format("$0,0");
    },
  },
};
</script>