<template>
  <div
    class="text-center"
    v-b-popover.hover.top.html="`${help}`"
    :title="help_title"
  >
    <b-card-title v-if="format != null || format != ''">
      {{ format_value(value, format) }}
    </b-card-title>
    <b-card-sub-title>{{ measure }}</b-card-sub-title>
    <b-badge :variant="badge_color">{{ badge }}</b-badge>
    <b-card-text>
      <i v-html="hover"></i>
    </b-card-text>
  </div>
</template>

<script>
var numeral = require("numeral");

export default {
  name: "SummaryMeasureItem",
  props: {
    measure: String,
    value: Number,
    type: String,
    hover: String,
    badge: { type: String, default: null },
    badge_color: { type: String, default: "secondary" },
    help: { type: String, default: "" },
    help_title: { type: String, default: "" },
    format: { type: String, default: null },
  },
  filters: {
    score(value) {
      return numeral(value).format("0,0.[0]");
    },
    number(value) {
      return numeral(value).format("0,0");
    },
    currency(value) {
      var x = value;
      // x = value > 100000 ? Math.round(value / 1000) * 1000 : x;
      // x = value > 1000000 ? Math.round(value / 10000) * 10000 : x;
      return numeral(x).format(this.format);
    },
    code(value) {
      return value.toUpperCase();
    },
  },
  methods: {
    format_value(value, format) {
      return numeral(value).format(format);
    },
  },
};
</script>

<style scoped>
.card-text {
  font-size: 9pt;
  color: #6c757d;
  padding-top: 0.2em;
}
.card-subtitle {
  font-size: 11pt;
}
.card-title {
  font-size: 16pt;
}
@media print {
  .card-title {
    font-size: 14pt;
  }
  .card-text {
    font-size: 9pt;
  }
  .card-subtitle {
    font-size: 10pt;
  }
}
</style>
