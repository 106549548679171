<script>
import { Radar } from "vue-chartjs";

export default {
  extends: Radar,
  props: ["score", "labels", "chart_data"],
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            borderWidth: 0.4,
            backgroundColor: "rgba(118, 185, 0, 0.2)",
            pointRadius: 1.8,
            pointBackgroundColor: "rgba(118, 185, 0, 1)",
            pointBorderColor: "rgba(118, 185, 0, 1)",
            pointHoverRadius: 1.8,

            data: [],
          },
        ],
      },
      options: {
        legend: {
          display: false,
        },
        scale: {
          ticks: {
            display: false,
            suggestedMin: 0,
            suggestedMax: 5,
            stepSize: 1,
          },
        },
        tooltips: {
          enabled: false,
          callbacks: {
            label: () => null,
            title: () => null,
          },
        },
        layout: {
          padding: {
            left: -13,
            right: -13,
            top: -13,
            bottom: -13,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.chartData.labels = this.chart_data.map((object) => object.measure);
    this.chartData.datasets[0].data = this.chart_data.map(
      (object) => object.score
    );
    this.renderChart(this.chartData, this.options);
  },
};
</script>
