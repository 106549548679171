<template>
  <b-form-group :label-cols="cols">
    <template v-slot:label v-if="`${label}` != 'undefined'">
      {{ label }}
      <!-- <b-iconstack
        v-if="modelled"
        v-b-tooltip.hover.top="'Field used in modelling'"
      >
        <b-icon-asterisk stacked variant="sgs-green" scale="0.6" />
        <b-icon stacked icon="circle" variant="sgs-green"></b-icon>
      </b-iconstack>
      <b-icon-blank v-if="modelled" font-scale="0.25" /> -->
      <b-icon-exclamation-circle
        variant="sgs-red"
        v-if="required"
        v-b-tooltip.hover.top="'Required field'"
      />
      <b-icon-blank v-if="required" font-scale="0.25" />
      <b-icon-question-circle
        variant="info"
        v-if="`${help}` != 'undefined'"
        :title="`${help_title}`"
        v-b-popover.hover.bottomleft.html="`${help}`"
      />
    </template>
    <b-input-group prepend="$" :size="size">
      <template v-slot:append v-if="`${decimal}` != ''">
        <b-input-group-text>{{ decimal }}</b-input-group-text>
      </template>
      <b-form-input
        :id="`${this.id}`"
        v-model="v_model_formatted"
        :form="form"
        type="text"
        :required="required"
        :size="size"
        :formatter="formatter"
        :placeholder="placeholder"
        @input="updateInput"
        :disabled="disabled"
      />
    </b-input-group>
  </b-form-group>
</template>

<script>
var numeral = require("numeral");

export default {
  name: "InputCurrency",
  props: {
    id: String,
    label: String,
    value: { type: [Number, String] },
    form: { type: String },
    format: { type: String, default: "0,0.[00]" },
    decimal: { type: String, default: ".00" },
    placeholder: { type: String, default: "Enter amount" },
    size: { type: String, default: "md" },
    cols: { type: [String, Number], default: 4 },
    help_title: { type: String, default: "" },
    help: String,
    required: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
  },
  model: {
    prop: "value",
    event: "change",
  },
  mounted() {
    this.v_model = this.value;
    this.v_model_formatted = this.formatter(this.v_model);
  },
  data() {
    return {
      v_model: this.value,
      v_model_formatted: this.v_model,
    };
  },
  methods: {
    updateInput() {
      this.$emit("change", numeral(this.v_model).value());
    },
    formatter(value) {
      if ((value == 0) | (value == "0")) {
        this.v_model = value;
        return value;
      } else if ((value == null) | (value == "")) {
        this.v_model = null;
        return null;
      } else {
        this.v_model = numeral(value).format(this.format);
        return numeral(value).format(this.format);
      }
    },
  },
  watch: {
    value() {
      let v = numeral(this.value);
      this.v_model = v.value();
      this.v_model_formatted = v.format(this.format);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  text-align: right;
}
// .form-group {
//   margin-bottom: 0;
// }
</style>

<style></style>
