<template>
  <div>
    <h5 v-if="label != null">
      {{ label }}
      <b-icon-exclamation-circle
        variant="sgs-red"
        v-b-tooltip.hover.top="'Required field'"
      />
    </h5>
    <b-table
      :id="`${object}` + '_dtable'"
      striped
      head-variant="light"
      hover
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter_string"
      @filtered="onFiltered"
      :selectable="selectable"
      :filter-function="filter_function"
      @row-selected="onRowSelected"
      ref="selectableTable"
      select-mode="multi"
      outlined
      sort-icon-left
      responsive
      :small="small"
    >
      <template #thead-top>
        <slot name="thead"></slot>
      </template>
      <template v-slot:head()="data">
        {{ data.field.label }}
        <b-icon-question-circle
          variant="info"
          v-if="`${data.field.help}` != 'undefined'"
          :title="`${data.field.help_title}`"
          v-b-popover.hover.bottomleft.html="`${data.field.help}`"
        />
      </template>
      <template v-slot:head(index)>
        <b-checkbox
          v-if="selectable"
          ref="select_all"
          v-model="select_all"
          :indeterminate.sync="indeterminate"
          v-b-popover.hover.top-left="'Select / Deselect all'"
        >
        </b-checkbox>
        Actions
      </template>
      <template v-slot:cell()="data">
        <span v-if="data.field.hasOwnProperty('format')">
          {{ format_value(data.value, data.field.format) }}
        </span>
        <span v-else>
          {{ data.value }}
        </span>
      </template>
      <template v-slot:cell(index)="data">
        <span>
          <b-link @click="edit_row(data.index)">
            <b-iconstack v-b-tooltip.hover.right="'Edit'">
              <b-icon-square-fill variant="sgs-yellow" />
              <b-icon-pencil-square scale=".8" variant="white" />
            </b-iconstack>
          </b-link>
          <b-link @click="delete_row(data.index)">
            <b-icon-x-square-fill
              variant="danger"
              v-b-tooltip.hover.right="'Delete'"
            />
          </b-link>
        </span>
      </template>

      <template v-slot:custom-foot>
        <b-tr>
          <b-td :colspan="fields.length" class="text-right">
            <b-button variant="sgs-green" @click="add_row()"> Add </b-button>
          </b-td>
        </b-tr>
      </template>
    </b-table>
    <br />

    <b-pagination
      v-if="(totalRows > perPage) & (perPage > 0)"
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      aria-controls="project_list"
      align="center"
    />

    <b-modal :id="`table_input_modal_${this.object}`" size="lg" centered>
      <template v-slot:modal-title> {{ modal_title }} </template>
      <b-form-group>
        <div v-for="(field, index) in fields" :key="index.toString()">
          <InputNumber
            :form="object"
            :id="`${field.key}` + '_number_input'"
            v-if="field.type == 'number'"
            v-model="new_item[field.key]"
            :required="field.required"
            :label="field.label"
            :placeholder="field.placeholder"
            :format="field.format"
            :min="field.min"
            :max="field.max"
            :unit="field.unit"
            :help="field.help"
            :help_title="field.help_title"
          />
          <InputCurrency
            :form="object"
            :id="`${field.key}` + '_currency_input'"
            v-else-if="field.type == 'currency'"
            v-model="new_item[field.key]"
            :required="field.required"
            :label="field.label"
            :placeholder="field.placeholder"
            :unit="field.unit"
            :help="field.help"
            :help_title="field.help_title"
          />
          <b-form-group v-else-if="field.type == 'text' & field.key == 'occupation'" label-cols="4">
            <template v-slot:label>
              Occupation
              <b-icon-question-circle
                variant="info"
                v-b-popover.hover.top.html="`Enter a custom job title or select an occupation from the HAYS database`"
              />
            </template>
            <b-input-group size="md">
              <template v-slot:append>
                <b-input-group-text 
                  class="appendSearch"
                  v-b-popover.hover.top.html="`Select an occupation from the HAYS Occupation List`"
                  @click="openHaysModal"
                >
                  <b-icon-search variant="light"/>
                </b-input-group-text>
              </template>
              <b-form-input
                :form="object"
                id="occupation_text_input"
                v-model="new_item[field.key]"
                type="text"
                size="md"
                :required="field.required"
                :placeholder="field.placeholder"
              >
              </b-form-input>
            </b-input-group>
          </b-form-group>
          <InputText
            :form="object"
            :id="`${field.key}` + '_text_input'"
            v-else-if="field.type == 'text'"
            v-model="new_item[field.key]"
            :required="field.required"
            :label="field.label"
            :placeholder="field.placeholder"
            :list="`${field.key}` + '_datalist'"
            :change="apply_salary_default(new_item)"
            :help="field.help"
            :help_title="field.help_title"
            autocomplete="off"
          />
          <InputSelect
            :form="object"
            :id="`${field.key}` + '_select_input'"
            v-else-if="field.type == 'selection'"
            v-model="new_item[field.key]"
            :required="field.required"
            :label="field.label"
            :text_field="field.text_field"
            :placeholder="field.placeholder"
            :options="field.list"
            :change="field.change(new_item)"
            :help="field.help"
            :help_title="field.help_title"
          />
        </div>
      </b-form-group>
      <template v-slot:modal-footer="{ cancel }">
        <b-col>
          <b-form
            v-if="modal_title == 'Add Row'"
            :id="object"
            @submit.prevent="add_row_confirm"
          >
            <b-button variant="success" type="submit" block>Confirm</b-button>
          </b-form>
          <b-form v-else :id="object" @submit.prevent="edit_row_confirm">
            <b-button variant="success" type="submit" block>Save</b-button>
          </b-form>
        </b-col>
        <b-col>
          <b-button variant="danger" @click="cancel()" block>Cancel</b-button>
        </b-col>
      </template>
    </b-modal>
    <b-modal :id="`hays_modal_${this.object}`" centered size="xl">
      <template v-slot:modal-title>HAYS Salary Guide Database</template>
      <Table
        :filter_toggle="true"
        :fields="hays_fields"
        :items="hays"
        :option1="locations"
        :option1_name="`location`"
        :option1_label="`Location`"
        :option2="sectors"
        :option2_name="`sector`"
        :option2_label="`Sector`"
        object="hays"
        :perPage="10"
        @pickHays="pickHays($event)"
      />
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="danger" @click="cancel()" block>Close</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import rows from "@/data/rows.json";
import InputCurrency from "@/components/form/InputCurrency.vue";
import InputNumber from "@/components/form/InputNumber.vue";
import InputText from "@/components/form/InputText.vue";
import InputSelect from "@/components/form/InputSelect.vue";
import Table from "@/components/Table.vue";
import hays from "@/data/hays.json";

var numeral = require("numeral");

export default {
  name: "DynamicTableV3",
  props: {
    label: { type: String, default: null },
    filter_toggle: { type: Boolean, default: false },
    selectable: Boolean,
    fields: { type: [Array, Object] },
    items: { type: [Array, Object] },
    hidden: { type: [Boolean, Object, Array], default: null },
    object: String,
    option1: String,
    option2: String,
    small: { type: Boolean, default: false },
    perPage: { type: Number, default: 0 },
  },
  components: {
    InputCurrency,
    InputNumber,
    InputText,
    InputSelect,
    Table
  },
  data() {
    return {
      rows,
      overwrite_salary: false,
      selected: [],
      isBusy: false,
      // perPage: this.perPageDefault,
      currentPage: 1,
      filter: "",
      totalRows: 0,
      filter1: "",
      filter2: "",
      select_all: false,
      indeterminate: true,
      new_item: {},
      modal_mode: "add",
      sector: null,
      location: null,
      locations: ["Greater Melbourne", "Regional Victoria", "Victoria", "Australia"],
      sectors: [
        "Accounting & Finance",
        "Architecture",
        "Banking",
        "Construction",
        "Contact Centres",
        "Defence",
        "Education",
        "Engineering",
        "Engineering (Energy)",
        "Engineering (Oil & Gas)",
        "Executive",
        "Facilities Management",
        "Healthcare",
        "Human Resources",
        "Insurance",
        "Legal",
        "Life Sciences",
        "Logistics",
        "Manufacturing & Operations",
        "Marketing & Digital",
        "Mining",
        "Office Support",
        "Policy & Strategy",
        "Procurement",
        "Property",
        "Retail",
        "Sales",
        "Sustainability and Environmental",
        "Technology"
      ],
      hays,
      hays_fields: [
        { 
          key: "value",
          label: "Occupation",
          sortable: true
        },
        { 
          key: "location",
          label: "Location",
          sortable: true
        },
        { 
          key: "sector",
          label: "Sector",
          sortable: true
        },
        { 
          key: "salary",
          label: "Salary",
          format: "$0,0.[00]",
          sortable: true
        },
        {
          key: "id",
          label: ""
        }
      ]
    };
  },
  methods: {
    toggleVisible(index, selected) {
      if (!selected) {
        if (this.select_all) {
          this.indeterminate = true;
        }
        this.$refs.selectableTable.unselectRow(index);
      } else {
        this.$refs.selectableTable.selectRow(index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
      this.$emit("filter", this.selected);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.selected = filteredItems;
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
      this.indeterminate = true;
      this.$emit("filter", this.selected);
    },
    toggleBusy() {
      this.isBusy = !this.isBusy;
    },
    deleteObject(event) {
      event.preventDefault();
      this.$bvModal.hide("delete_object");
      this.$emit("deleteObject", this.input);
    },
    filter_function_nest(row, filter) {
      if (filter != "") {
        if (
          JSON.stringify(row)
            .toLowerCase()
            .includes(filter.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    filter_function(row, filter) {
      return (
        this.filter_function_nest(row, filter.text) &&
        this.filter_function_nest(row.company, filter.filter1) &&
        this.filter_function_nest(row.opportunity_owner, filter.filter2)
      );
    },
    format_value(value, format) {
      let valuestring = numeral(value).format(format);
      return valuestring.replace("NaN", "0");
    },
    openHaysModal() {
      this.$bvModal.show("hays_modal_" + this.object);
    },
    add_row() {
      var new_object = {};
      this.fields.forEach((item) => (new_object[item["key"]] = null));
      this.new_item = new_object;
      this.overwrite_salary = false;
      this.modal_title = "Add Row";
      this.$bvModal.show("table_input_modal_" + this.object);
    },
    add_row_confirm(event) {
      event.preventDefault();
      if (this.hidden != null) {
        Object.keys(this.hidden).forEach(
          (key, i) => (this.new_item[key] = Object.values(this.hidden)[i])
        );
      }
      this.items.push(JSON.parse(JSON.stringify(this.new_item)));
      if (Object.keys(this.new_item).includes("year")) {
        this.new_item.year++;
      }
      this.sortItems();
      this.$emit("change");
    },
    edit_row(index) {
      var new_object = {};
      this.fields.forEach(
        (item) => (new_object[item["key"]] = this.items[index][item["key"]])
      );
      new_object["index"] = index;
      this.new_item = new_object;
      this.overwrite_salary = true;
      this.modal_title = "Edit Row";
      this.$bvModal.show("table_input_modal_" + this.object);
    },
    edit_row_confirm(event) {
      event.preventDefault();
      let index = this.new_item["index"];
      Object.keys(this.new_item).forEach(
        (key) => (this.items[index][key] = this.new_item[key])
      );
      this.$bvModal.hide("table_input_modal_" + this.object);
      this.sortItems();
      this.$emit("change");
      this.$forceUpdate();
    },
    delete_row(index) {
      if (index > -1) {
        this.items.splice(index, 1);
        this.sortItems();
        this.$emit("change");
      }
    },
    sortItems() {
      if (Object.keys(this.new_item).includes("year")) {
        this.items.sort(function(a, b) {
          if (a.year < b.year) return -1;
          if (a.year > b.year) return 1;
          return 0;
        });
      }
      if (Object.keys(this.new_item).includes("occupation")) {
        this.items.sort(function(a, b) {
          if (a.occupation < b.occupation) return -1;
          if (a.occupation > b.occupation) return 1;
          return 0;
        });
      }
      if (Object.keys(this.new_item).includes("skill")) {
        this.items.sort(function(a, b) {
          if (a.skill < b.skill) return -1;
          if (a.skill > b.skill) return 1;
          return 0;
        });
      }
    },
    reset_modal() {
      this.fields.forEach((item) => (this.new_item[item["key"]] = ""));
    },
    reset(year) {
      var new_object = {};
      var fields_list = JSON.parse(JSON.stringify(this.fields));
      fields_list.forEach(function(element) {
        new_object[element.key] = "";
      });
      this.new_item = new_object;
      this.new_item.year = year ? year : null;
    },
    pickHays(event) {
      let hays_list = this.hays;
      hays_list = hays_list.filter((item) => item.id == event.selected_id);
      if (hays_list.length >= 1) {
        this.new_item.occupation = hays_list[0].value + " - " + hays_list[0].location
        this.new_item.ave_salary = hays_list[0].salary
        this.$bvModal.hide("hays_modal_" + this.object);
      }
    },
    // apply_salary_default(new_item) {
    //   if (Object.keys(new_item).includes("occupation")) {
    //     if (new_item.occupation != null) {
    //       if (new_item.occupation.includes(" - Greater Melbourne")) {
    //         new_item.occupation = new_item.occupation.replace(
    //           " - Greater Melbourne",
    //           ""
    //         );
    //         this.location = "Greater Melbourne";
    //         console.log(this.hays_filtered);
    //         this.apply_salary_default(new_item);
    //       } else if (new_item.occupation.includes(" - Regional Victoria")) {
    //         new_item.occupation = new_item.occupation.replace(
    //           " - Regional Victoria",
    //           ""
    //         );
    //         this.location = "Regional Victoria";
    //         console.log(this.hays_filtered);
    //         this.apply_salary_default(new_item);
    //       } else if (new_item.occupation.includes(" - Victoria")) {
    //         new_item.occupation = new_item.occupation.replace(
    //           " - Victoria",
    //           ""
    //         );
    //         this.location = "Victoria";
    //         console.log(this.hays_filtered);
    //         this.apply_salary_default(new_item);
    //       }

    //       let search = this.hays_filtered.filter(
    //         (obj) => obj.value == new_item.occupation
    //       );
    //       if (this.overwrite_salary == false) {
    //         if (search.length >= 1) {
    //           new_item.ave_salary = search[0].salary;
    //         } else {
    //           new_item.ave_salary = null;
    //         }
    //       }
    //     }
    //   }
    // },
  },
  filters: {},
  computed: {
    filter_string() {
      let filter_string = {
        text: this.filter,
        filter1: this.filter1,
        filter2: this.filter2,
      };
      return filter_string;
    },
    row_groups() {
      let x = {};
      return x;
    },
    groupedItems() {
      const groups = {};

      let categories = {};
      this.fields.forEach((item) => {
        if (item.grouped) {
          categories.push(item.key);
        }
      });

      this.items.forEach((item) => {
        if (groups[item.type]) {
          groups[item.type].push(item);
        } else {
          groups[item.type] = [item];
        }
      });

      return groups;
    },
    // hays_filtered() {
    //   var hays_list = this.hays;
    //   if (this.location != null && this.location != "") {
    //     hays_list = hays_list.filter((item) => item.location == this.location);
    //   }
    //   if (this.sector != null && this.sector != "") {
    //     hays_list = hays_list.filter((item) => item.sector == this.sector);
    //   }
    //   return hays_list;
    // },
  },
  created() {
    this.reset_modal();
  },
  mounted() {
    this.sortItems();
    this.totalRows = this.items.length;
  },
  updated() {
    this.totalRows = this.items.length;
  },
  watch: {
    select_all() {
      if (this.select_all) {
        this.$refs.selectableTable.selectAllRows();
      } else {
        this.$refs.selectableTable.clearSelected();
      }
    },
    selected() {
      if (this.selected.length == 0) {
        this.select_all = false;
      }
    },
    items() {
      this.totalRows = this.items.length;
      // this.$emit("change", this.items);
    },
    fields() {
      this.$forceUpdate();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.b-icon {
  margin-right: 0.3em;
}
.cell-head {
  font-size: 75%;
}

.appendSearch {
  background-color: #ffc107 !important;
}
.appendSearch:hover {
  background-color: #f1b706 !important;
}
</style>
