<template>
  <div>
    <PageTitle
      variant="sgs-green"
      title="Edit"
      text="Here's the scenario that you would like to edit. <br/> Submit this form to save your changes. <br/> We will automatically reassess this scenario for you."
    ></PageTitle>
    <b-overlay :show="loading" rounded="sm" no-wrap />
    <b-container v-if="loaded">
      <OpportunityScenarioForm
        :data="form"
        @onSubmit="onSubmit($event)"
        @submitDisqualified="updateDisqualified($event)"
      />
    </b-container>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import OpportunityScenarioForm from "@/components/OpportunityScenarioForm.vue";

export default {
  name: "EditOpportunityScenario",
  components: {
    OpportunityScenarioForm,
    PageTitle,
  },
  data() {
    return {
      loading: true,
      loaded: false,
      form: {},
      companies: [],
    };
  },
  created() {
    this.$store
      .dispatch("getScenario", {
        data: this.$route.params.id,
      })
      .then((response) => {
        this.form = response.data.scenario;
        console.log(this.form);
        this.loaded = true;
        this.loading = false;
      })
      .catch((error) => {
        this.serverError = error.response.data;
      });
  },
  methods: {
    updateDisqualified(event) {
      this.loading = true;
      this.$store
        .dispatch("editScenarioDisqualified", {
          id: this.$route.params.id,
          data: event,
        })
        .then((response) => {
          this.loading = false;
          console.log(response);
          this.$router.push({
            name: "view_opportunity",
            params: {
              id: response.data.public_id,
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          alert(error.response.data);
          this.serverError = error.response.data;
        });
    },
    onSubmit(event) {
      this.loading = true;
      this.$store
        .dispatch("editScenario", {
          id: this.$route.params.id,
          data: event,
        })
        .then((response) => {
          this.loading = false;
          this.$router.push({
            name: "view_assessment",
            params: {
              id: response.data.public_id,
            },
          });
        })
        .catch((error) => {
          this.loading = false;
          alert(error.response.data);
          this.serverError = error.response.data;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
