<template>
  <div>
    <PageTitle
      variant="sgs-blue"
      title="Compare"
      text="Search records to compare cost, strategic impact, and data quality."
    ></PageTitle>
    <b-overlay :show="!loaded" rounded="sm" no-wrap />
    <b-container fluid>
      <b-row>
        <b-col v-if="comparedItem.length === 1">
          <h3>Comparing project</h3>
        </b-col>
        <b-col>
          <b-form style="text-align: right; justify-content: flex-end;" inline>
            <label for="inline-form-chart-number">Number of charts </label>
            <b-icon-blank />
            <b-select
              id="inline-form-chart-number"
              class="mb-2 mr-sm-2 mb-sm-0"
              v-model="chart_number"
              style="width: 50px"
              :options="[1, 2, 3]"
            >
            </b-select>
            <b-button variant="primary" @click="print">
              Print
              <b-icon-printer-fill shift-h="3px" scale=".9" shift-v="1px" />
            </b-button>
          </b-form>
          <br />
        </b-col>
      </b-row>
      <b-row v-if="comparedItem.length === 1">
        <b-col>
          <Table
            :items="comparedItem"
            :fields="fields.filter(item => !item.hidden)"
            object="assessment"
            :filter_toggle="false"
            style="margin-bottom: -1rem"
          />
          <br />
          <b-container fluid class="legend text-center">
            <span>
              Legend
            </span>
            <b-badge pill variant="sgs-orange">This project</b-badge>
            <b-badge pill variant="sgs-periwinkle">Other projects</b-badge>
          </b-container>
        </b-col>
      </b-row>
      <!-- <b-row>
        <h3>Build charts</h3>
      </b-row> -->
      <b-row :cols="chart_number">
        <b-col v-for="(item, index) in chart_number" :key="item">
          <BubbleChart
            :chart_data="filteredItems"
            :selected_data="comparedItem"
            :y_axis="charts[index].yaxis"
            :x_axis="charts[index].xaxis"
            :trendline="charts[index].trendline"
            :height="400"
          />
          <br />
          <b-input-group class="axis_select">
            <b-select v-model="charts[index].yaxis" :options="axis_options">
              <template v-slot:first>
                <b-form-select-option :value="null" disabled>
                  Select Y-Axis
                </b-form-select-option>
              </template>
            </b-select>
            <b-select v-model="charts[index].xaxis" :options="axis_options">
              <template v-slot:first>
                <b-form-select-option :value="null" disabled>
                  Select X-Axis
                </b-form-select-option>
              </template>
            </b-select>
          </b-input-group>
          <!-- <div class="text-center">
          <b-form-checkbox v-model="charts[index].trendline" switch>
            Show trend line
          </b-form-checkbox>
          </div> -->
        </b-col>
      </b-row>

      <br />
      <br />
      <b-row>
        <b-col>
          <b-card class="search" no-body>
            <b-card-header>
              <b-card-title
                class="d-flex justify-content-between align-items-center"
              >
                Search
                <b-icon-group
                  name="collapse-icon"
                  @click="collapse_visible = !collapse_visible"
                >
                  <b-icon-chevron-up v-if="collapse_visible" />
                  <b-icon-chevron-down v-else />
                </b-icon-group>
              </b-card-title>
              <b-card-sub-title>
                Find scenarios to compare using these filters:
              </b-card-sub-title>
            </b-card-header>
            <b-collapse v-model="collapse_visible" id="collapse-items">
              <b-card-body>
                <b-form>
                  <b-row>
                    <b-col>
                      <b-form-group label="Investment type:" label-cols="5">
                        <b-input-group>
                          <b-select
                            v-model="search.investment_type"
                            :options="investment_types"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null">
                                Select FDI / DI
                              </b-form-select-option>
                            </template>
                          </b-select>
                        </b-input-group>
                      </b-form-group>
                      <b-form-group label="Financial year:" label-cols="5">
                        <b-input-group>
                          <b-select v-model="search.fyear" :options="fyears">
                            <template v-slot:first>
                              <b-form-select-option :value="null">
                                Select financial year
                              </b-form-select-option>
                            </template>
                          </b-select>
                        </b-input-group>
                      </b-form-group>
                      <b-form-group label="Opportunity type:" label-cols="5">
                        <b-input-group>
                          <b-select
                            v-model="search.opp_type"
                            :options="types_data"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null">
                                Select opportunity type
                              </b-form-select-option>
                            </template>
                          </b-select>
                        </b-input-group>
                      </b-form-group>
                      <b-form-group label="Opportunity stage:" label-cols="5">
                        <b-input-group>
                          <b-select
                            v-model="search.opp_stage"
                            :options="stages_data"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null">
                                Select opportunity stage
                              </b-form-select-option>
                            </template>
                          </b-select>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Company:" label-cols="5">
                        <b-input-group>
                          <b-select
                            v-model="search.company"
                            :options="companies"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null">
                                Select company
                              </b-form-select-option>
                            </template>
                          </b-select>
                        </b-input-group>
                      </b-form-group>
                      <b-form-group label="Country of Investor:" label-cols="5">
                        <b-input-group>
                          <b-select
                            v-model="search.country"
                            :options="country_data"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null">
                                Select country of investor
                              </b-form-select-option>
                            </template>
                          </b-select>
                        </b-input-group>
                      </b-form-group>
                      <b-form-group label="Owner:" label-cols="5">
                        <b-input-group>
                          <b-select v-model="search.owner" :options="owners">
                            <template v-slot:first>
                              <b-form-select-option :value="null">
                                Select opportunity owner
                              </b-form-select-option>
                            </template>
                          </b-select>
                        </b-input-group>
                      </b-form-group>
                      <b-form-group label="Cost type:" label-cols="5">
                        <b-input-group>
                          <b-select
                            v-model="search.cost_type"
                            :options="cost_types"
                          >
                          </b-select>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <b-form-group label="Sector:" label-cols="5">
                        <b-input-group>
                          <b-select v-model="search.sector" :options="sectors">
                            <template v-slot:first>
                              <b-form-select-option :value="null">
                                Select sector
                              </b-form-select-option>
                            </template>
                          </b-select>
                        </b-input-group>
                      </b-form-group>
                      <b-form-group label="Grant range:" label-cols="5">
                        <b-input-group>
                          <b-select
                            v-model="search.grant_range"
                            :options="grant_range"
                            :disabled="search.gross_cost_value == null"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null">
                                Show all
                              </b-form-select-option>
                            </template>
                          </b-select>
                        </b-input-group>
                      </b-form-group>
                      <b-form-group label="Completion status:" label-cols="5">
                        <b-input-group>
                          <b-select
                            v-model="search.is_complete"
                            :options="yes_no"
                          >
                            <template v-slot:first>
                              <b-form-select-option :value="null">
                                Show all
                              </b-form-select-option>
                            </template>
                          </b-select>
                        </b-input-group>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-button variant="sgs-blue" block @click="filter_scenarios">
                    Search
                    <b-icon-search />
                  </b-button>
                </b-form>
              </b-card-body>
              <b-card-footer>{{ serverError }}</b-card-footer>
            </b-collapse>
          </b-card>
        </b-col>
      </b-row>

      <br />
      <Table
        :items="items"
        :fields="fields.filter(item => !item.hidden)"
        v-if="loaded"
        object="assessment"
        :filter_toggle="true"
        @filter="onFilter"
      >
        <template v-slot:params>
          <b-col cols="1">
            <!-- <b-select
              v-model="selectedFields"
              :options="fields"
              text-field="label"
              value-field="key"
              multiple
            >
            </b-select>
            <b-icon-download /> -->
            <ExportData :data="items" :field="fields" name="export.csv" />
          </b-col>
        </template>
      </Table>
    </b-container>
  </div>
</template>

<script>
// import DateRangePicker from "vue2-daterange-picker";
import PageTitle from "@/components/PageTitle.vue";
import BubbleChart from "@/components/charts/BubbleChart";
import country_data from "@/data/country.json";
import stages_data from "@/data/stage.json";
import types_data from "@/data/type.json";
import Table from "@/components/Table.vue";
import ExportData from "@/components/ExportData.vue";
import cost_data_quality_data from "@/data/cost_data_quality.json";
import sectors from "@/data/sector.json";
import investment_types from "@/data/investment_type.json";
import fyears from "@/data/financial_years.json";

export default {
  name: "CompareResults",
  components: {
    BubbleChart,
    // DateRangePicker,
    PageTitle,
    Table,
    ExportData,
  },
  data() {
    return {
      loaded: false,
      serverError: "",
      search: {
        investment_type: null,
        opp_type: null,
        opp_stage: null,
        country: null,
        fyear: null,
        company: null,
        owner: null,
        cost_data: null,
        cost_type: null,
        sector: null,
        is_complete: false,
        selected_id: null,
        gross_cost_value: null,
        grant_range: null,
      },
      report_year: null,
      selection: "",
      selected: null,
      companies: [],
      owners: [],
      collapse_visible: true,
      chart_number: 3,
      charts: [
        {
          xaxis: {
            label: "Strategic Impact Score",
            field: "strategic_impact_score",
            format: "0.[0]",
          },
          yaxis: {
            label: "Cost per job created",
            field: "emp_cost",
            format: "$0,0.[00]",
          },
          trendline: true,
        },
        {
          xaxis: {
            label: "Strategic Impact Score",
            field: "strategic_impact_score",
            format: "0.[0]",
          },
          yaxis: {
            label: "GSP uplift per dollar support",
            field: "gsp_cost",
            format: "$0,0.[00]",
          },
          trendline: true,
        },
        {
          xaxis: {
            label: "GSP uplift",
            field: "gsp_pv_total",
            format: "$0,0.[00]",
          },
          yaxis: {
            label: "GSP uplift per dollar support",
            field: "gsp_cost",
            format: "$0,0.[00]",
          },
          trendline: true,
        },
        // { xaxis: null, yaxis: null },
        // { xaxis: null, yaxis: null },
        // { xaxis: null, yaxis: null },
      ],
      axis_options: [
        {
          text: "Strategic Impact Score",
          value: {
            label: "Strategic Impact Score",
            field: "strategic_impact_score",
            format: "0.[0]",
          },
        },
        {
          text: "Gross grant and facilitation value",
          value: {
            label: "Gross grant and facilitation value",
            field: "gross_cost_value",
            format: "$0,0.[00]",
          },
        },
        {
          text: "Total Grant",
          value: {
            label: "Total Grant",
            field: "grant_total",
            format: "$0,0.[00]",
          },
        },
        {
          text: "Facilitation days",
          value: {
            label: "Facilitation days",
            field: "hr_day",
            format: "0",
          },
        },
        {
          text: "Capital Expenditure",
          value: {
            label: "Capital Expenditure",
            field: "capex",
            format: "$0,0.[00]",
          },
        },
        {
          text: "Direct Employment",
          value: {
            label: "Direct Employment",
            field: "direct_emp",
            format: "0",
          },
        },
        {
          text: "Wage Expenditure",
          value: {
            label: "Wage Expenditure",
            field: "wage_expenditure",
            format: "$0,0.[00]",
          },
        },
        {
          text: "Innovation expenditure",
          value: {
            label: "Innovation expenditure",
            field: "innovation_expenditure",
            format: "$0,0.[00]",
          },
        },
        {
          text: "GSP uplift",
          value: {
            label: "GSP uplift",
            field: "gsp_pv_total",
            format: "$0,0.[00]",
          },
        },
        {
          text: "State tax revenue",
          value: {
            label: "State tax revenue",
            field: "vic_tax_pv_total",
            format: "$0,0.[00]",
          },
        },
        {
          text: "Total jobs created",
          value: {
            label: "Total jobs created",
            field: "emp_pv_total",
            format: "0",
          },
        },
        {
          text: "GSP uplift per dollar support",
          value: {
            label: "GSP uplift per dollar support",
            field: "gsp_cost",
            format: "$0,0.[00]",
          },
        },
        {
          text: "State tax revenue per dollar support",
          value: {
            label: "State tax revenue per dollar support",
            field: "vic_tax_cost",
            format: "$0,0.[00]",
          },
        },
        {
          text: "Cost per job created",
          value: {
            label: "Cost per job created",
            field: "emp_cost",
            format: "$0,0.[00]",
          },
        },
      ],
      fyears,
      country_data,
      stages_data,
      types_data,
      cost_data_quality_data,
      sectors,
      investment_types,
      cost_types: [
        { text: "Show all", value: null },
        { text: "Grant only", value: "Grant" },
        { text: "Facilitation only", value: "Facilitation" },
        { text: "Both only", value: "Both" },
      ],
      yes_no: [
        // { text: "Complete projects only", value: true },
        { text: "Claimed projects only", value: false },
      ],
      grant_range: [
        { text: "± 25,000", value: 25000 },
        { text: "± 50,000", value: 50000 },
        { text: "± 100,000", value: 100000 },
        { text: "± 500,000", value: 500000 },
        { text: "± 1,000,000", value: 1000000 },
        { text: "± 5,000,000", value: 5000000 },
        { text: "± 10,000,000", value: 10000000 },
        { text: "± 50,000,000", value: 50000000 },
      ],
      items: [],
      selectedFields: [],
      fields: [
        {
          key: "id",
          label: "",
        },
        {
          key: "fyear",
          label: "FY",
          sortable: true,
        },
        {
          key: "investment_type",
          label: "FDI/DI",
          sortable: true,
          hidden: true,
        },
        {
          key: "company",
          label: "Company Name",
          sortable: true,
        },
        {
          key: "opportunity_code",
          label: "Opportunity Code",
          thClass: "text-right",
          tdClass: "text-right",
          sortable: true,
          hidden: true,
        },
        {
          key: "opportunity_scenario",
          label: "Opportunity",
        },
        {
          key: "scenario",
          label: "Scenario",
          hidden: true,
        },
        {
          key: "direct_emp",
          label: "Direct Jobs (FTE)",
          thClass: "text-right",
          tdClass: "text-right",
          format: "0,0.[00]",
          sortable: true,
        },
        {
          key: "wage_expenditure",
          label: "Wages (AUD)",
          thClass: "text-right",
          tdClass: "text-right",
          format: "$0,0.[00]",
          sortable: true,
        },
        {
          key: "capex",
          label: "Capital expenditure (AUD)",
          thClass: "text-right",
          tdClass: "text-right",
          format: "$0,0.[00]",
          sortable: true,
        },
        {
          key: "innovation_expenditure",
          label: "Innovation expenditure (AUD)",
          thClass: "text-right",
          tdClass: "text-right",
          format: "$0,0.[00]",
          sortable: true,
        },
        {
          key: "country",
          label: "Investor Country",
          sortable: true,
        },
        {
          key: "sector",
          label: "Sector",
          sortable: true,
        },
        {
          key: "grant_total",
          label: "Grant (AUD)",
          format: "$0,0.[00]",
          thClass: "text-right",
          tdClass: "text-right",
          sortable: true,
        },
        {
          key: "hr_day",
          label: "Facilitation days",
          format: "0,0.[00]",
          thClass: "text-right",
          tdClass: "text-right",
          sortable: true,
        },
        {
          key: "region",
          label: "Region",
          sortable: true,
        },
        // hidden columns shown upon export
        {
          key: "opp_type",
          label: "Investment Type",
          sortable: true,
          hidden: true,
        },
        {
          key: "opp_owner",
          label: "Opportunity Owner",
          sortable: true,
          hidden: true,
        },
        {
          key: "team_name",
          label: "Team Name",
          sortable: true,
          hidden: true,
        },
        {
          key: "gsp_pv_total",
          label: "GSP Uplift",
          thClass: "text-right",
          tdClass: "text-right",
          format: "$0,0.[00]",
          sortable: true,
          hidden: true,
        },
        {
          key: "gsp_cost",
          label: "GSP Uplift per $ Cost",
          thClass: "text-right",
          tdClass: "text-right",
          format: "$0,0.[00]",
          sortable: true,
          hidden: true,
        },
        {
          key: "vic_tax_pv_total",
          label: "State tax revenue",
          thClass: "text-right",
          tdClass: "text-right",
          format: "$0,0.[00]",
          sortable: true,
          hidden: true,
        },
        {
          key: "vic_tax_cost",
          label: "State Tax Revenue per $ Cost",
          thClass: "text-right",
          tdClass: "text-right",
          format: "$0,0.[00]",
          sortable: true,
          hidden: true,
        },
        {
          key: "emp_pv_total",
          label: "Total Jobs Created",
          thClass: "text-right",
          tdClass: "text-right",
          format: "0,0.[00]",
          sortable: true,
          hidden: true,
        },
        {
          key: "emp_cost",
          label: "Cost per job created",
          thClass: "text-right",
          tdClass: "text-right",
          format: "$0,0.[00]",
          sortable: true,
          hidden: true,
        },
        // {
        //   key: "score_data_quality",
        //   label: "Data Quality",
        //   thClass: "text-right",
        //   tdClass: "text-right",
        //   sortable: true,
        // },
        {
          key: "strategic_impact_score",
          label: "Strategic Impact",
          thClass: "text-right",
          tdClass: "text-right",
          sortable: true,
        },
      ],
      filteredItems: [],
      comparedItem: [],
    };
  },
  methods: {
    filter_scenarios() {
      this.loaded = false;
      this.$store
        .dispatch("compareAssessment", {
          data: this.search,
        })
        .then((response) => {
          this.items = response.data.compare;
          this.filteredItems = response.data.compare;
          this.loaded = true;
          this.serverError = "";
        })
        .catch((error) => {
          this.serverError = error.response.data;
        });
    },
    onFilter(event) {
      this.filteredItems = event;
    },
    print() {
      // resize radar chart
      var chart = document.getElementsByClassName("chartjs-render-monitor");

      chart.forEach((e) => {
        e.style.width = "100%";
        e.style.height = "100%";
      });

      window.print();
    },
  },
  created() {
    if (this.$route.params.id) {
      this.search.selected_id = this.$route.params.id;
      this.$store
        .dispatch("get", {
          url: "/assessment/compare/" + this.$route.params.id,
          data: {},
        })
        .then((response) => {
          this.comparedItem = response.data.compare;
          this.search.sector = response.data.compare[0].sector;
          this.search.gross_cost_value =
            response.data.compare[0].gross_cost_value;
          console.log(this.comparedItem);
        });
    }
    this.$store.dispatch("getCompanies").then((response) => {
      this.companies = response.data.companies;
      this.owners = response.data.owners;
      this.loaded = true;
    });
  },
  watch: {
    charts() {
      console.log(this.charts);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.legend {
  span:first-child {
    margin: 0.25em;
    font-size: 9pt;
    letter-spacing: -0.01em;
    font-family: Arial;
    text-rendering: optimizeLegibility;
    text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
    color: #555;
  }
  .badge {
    margin: 0.25em;
  }
}

@media print {
  .item {
    width: 467px;
    padding: 0.3em 0em;
  }

  .col {
    padding-left: 7px;
    padding-right: 7px;
  }

  .row {
    &:first-child {
      display: none;
    }
  }

  .card-title {
    font-size: 1.2em;
  }

  .card-header {
    padding-bottom: 0.2em;
  }

  .card-body {
    padding-top: 0.3em;
    padding-bottom: 0.2em;
  }

  .timestamp {
    display: flex;
    margin: 0;
    padding: 0;
    text-align: center;
  }

  .hanging-bar {
    display: none;
  }
  .buttons {
    display: none;
  }
  .search {
    display: none;
  }
  .axis_select {
    display: none;
  }
}
</style>

<style lang="scss">
@media print {
  .table_filter {
    display: none;
  }
}
</style>
