<script>
import { Radar } from "vue-chartjs";

export default {
  extends: Radar,
  props: ["score", "labels", "chart_data", "chart_benchmarks"],
  data() {
    return {
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Project score",
            borderWidth: 1,
            backgroundColor: "rgba(118, 185, 0, 0.2)",
            pointRadius: 3,
            pointBackgroundColor: "rgba(118, 185, 0, 1)",
            pointBorderColor: "rgba(118, 185, 0, 1)",
            pointHoverRadius: 4.5,

            data: [],
          },
          {
            label: "Average score of all completed projects",
            borderWidth: 1,
            backgroundColor: "rgba(228, 127, 0, 0.2)",
            pointRadius: 3,
            pointBackgroundColor: "rgba(228, 127, 0, 1)",
            pointBorderColor: "rgba(228, 127, 0, 1)",
            pointHoverRadius: 4.5,

            data: [],
          },
        ],
      },
      options: {
        legend: {
          display: true,
        },
        scale: {
          ticks: {
            suggestedMin: 0,
            suggestedMax: 5,
            stepSize: 1,
          },
        },
        tooltips: {
          custom: function (tooltip) {
            if (!tooltip) return;
            //disable displaying colour box
            tooltip.displayColors = false;
          },
          callbacks: {
            title: function (tooltipItem, data) {
              return [data.labels[tooltipItem[0].index]];
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.chartData.labels = this.chart_data.map((object) => object.measure);
    this.chartData.datasets[0].data = this.chart_data.map(
      (object) => object.score
    );
    if (this.chart_benchmarks != null) {
      this.chartData.datasets[1].data = this.chart_benchmarks.map(
        (object) => object.score
      );
    }
    this.renderChart(this.chartData, this.options);
  },
};
</script>