<template>
  <div>
    <b-card no-body>
      <!-- <template v-slot:header>
        <b-card-title class="d-flex justify-content-between align-items-center">
          Investor Survey
        </b-card-title>
      </template> -->
      <b-card-body>
        <b-form @submit="onSubmit" @reset="onReset">
          <InputRange
            :required="true"
            id="radio-rating"
            v-model="form.rating"
            label="How likely would you recommend working with IV to a friend of colleague?"
            :min="0"
            :max="10"
            help="0 - Unlikely<br/>10 Likely"
          />
          <InputTextArea
            :rows="12"
            :required="true"
            id="textarea-comments"
            v-model="form.comments"
            label="Please share any comments or suggestions."
            placeholder="Enter your comments or suggestions..."
          />

          <br />
          <b-row>
            <b-col cols="4" />
            <b-col>
              <b-button type="reset" variant="danger" block>Reset</b-button>
            </b-col>
            <b-col>
              <b-button type="submit" variant="primary" block>
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import InputRange from "@/components/form/InputRange.vue";
import InputTextArea from "@/components/form/InputTextArea.vue";

// import FormValidationOpportunity from "@/components/FormValidationOpportunity.vue";

export default {
  name: "OpportunityForm",
  props: ["companies", "owners", "data"],
  components: {
    InputRange,
    InputTextArea,
  },
  data() {
    return {
      form: {
        // third tab
        rating: 5,
        comments: "",
      },
      show: true,
      tabIndex: 0,
    };
  },

  methods: {
    onReset() {
      // Reset our form values
      this.form.rating = 5;
      this.form.comments = "";

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    onSubmit(event) {
      event.preventDefault();
      this.$emit("onSubmit", this.form);
    },
    next(event) {
      event.preventDefault();
      this.tabIndex++;
    },
    previous() {
      this.tabIndex--;
    },
  },
  beforeMount() {
    if (this.data) {
      this.form = this.data;
    }
  },
  computed: {
    characters_left() {
      let char_left = 1000 - this.form.description.length;
      if (char_left >= 0) return char_left + " characters left";
      else return Math.abs(char_left) + " characters too long";
    },
  },
  watch: {
    "form.opportunity_code": function (val) {
      if (val.length > 4 && val.length < 13) {
        this.opp_code_state = true;
      }
      if (val.length > 12) {
        this.opp_code_state = false;
      }
    },
    "form.opportunity_name": function (val) {
      if (val.length > 4 && val.length < 51) {
        this.opp_name_state = true;
      }
      if (val.length > 50) {
        this.opp_name_state = false;
      }
    },
    "form.opportunity_owner": function (val) {
      if (val.length > 4 && val.length < 51) {
        this.opp_owner_state = true;
      }
      if (val.length > 50) {
        this.opp_owner_state = false;
      }
    },
    "form.description": function (val) {
      if (val.length > 4 && val.length < 1001) {
        this.desc_state = true;
      }
      if (val.length > 1000) {
        this.desc_state = false;
      }
    },
    "form.company": function (val) {
      if (val.length > 3 && val.length < 51) {
        this.company_state = true;
      }
      if (val.length > 50) {
        this.company_state = false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
