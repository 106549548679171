<template>
  <b-form-group label-cols="4">
    <template v-slot:label>
      {{ label }}
      <b-icon-exclamation-circle
        variant="sgs-red"
        v-if="required"
        v-b-tooltip.hover.top="'Required field'"
      />
      <b-icon-blank v-if="required" font-scale="0.25" />
      <b-icon-question-circle
        variant="info"
        v-if="`${help}` != 'undefined'"
        :title="`${help_title}`"
        v-b-popover.hover.bottomleft.html="`${help}`"
      />
    </template>
    <b-input-group>
      <b-form-radio-group
        :id="`${this.id}`"
        :name="`${this.id}`"
        v-model="v_model"
        :options="options"
        :required="required"
        :disabled="disabled"
        @input="updateInput"
      >
        <template v-slot:first></template>
        <b-form-radio value="NA" v-if="na_option">Not Applicable</b-form-radio>
      </b-form-radio-group>
    </b-input-group>
    <slot></slot>
  </b-form-group>
</template>

<script>
export default {
  name: "InputRadio",
  props: {
    id: String,
    label: String,
    checked: { type: [String, Boolean] },
    options: Array,
    na_option: { type: Boolean, default: false },
    help_title: { type: String, default: "" },
    help: String,
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: true },
  },
  model: {
    prop: "checked",
    event: "change",
  },
  data() {
    return {
      v_model: "",
    };
  },
  beforeMount() {
    this.v_model = this.checked;
  },
  methods: {
    updateInput() {
      this.$emit("change", this.v_model);
    },
  },
  watch: {
    checked() {
      this.v_model = this.checked;
    },
  },
};
</script>

<style scoped>
/* added padding to align text to label baseline */
.input-group {
  padding-top: 0.5em;
}
</style>
