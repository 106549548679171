<template>
  <div>
    <b-card>
      <template v-slot:header>
        <b-card-title class="d-flex justify-content-between align-items-center"
          >{{ header }}
          <b-icon-group
            v-if="more_items != null"
            @click="collapse_visible = !collapse_visible"
          >
            <b-icon-chevron-up v-if="collapse_visible" />
            <b-icon-chevron-down v-else />
          </b-icon-group>
        </b-card-title>
        <b-card-sub-title>{{ subheader }}</b-card-sub-title>
      </template>
      <b-row :cols="items.length">
        <b-col class="item" v-for="value in items" :key="value.measure">
          <SummaryMeasureItem
            :measure="value.measure"
            :value="value.value"
            :format="value.format"
            :hover="value.unit"
            :badge="value.badge"
            :badge_color="value.badge_color"
            :help="value.help"
            :help_title="value.help_title"
          />
        </b-col>
      </b-row>
      <b-collapse
        v-if="more_items != null"
        v-model="collapse_visible"
        id="collapse-items"
      >
        <hr v-if="more_items != null" />
        <b-row
          v-if="Object.prototype.toString.call(more_items) === '[object Array]'"
        >
          <b-col class="item" v-for="value in more_items" :key="value.measure">
            <SummaryMeasureItem
              :measure="value.measure"
              :value="value.value"
              :format="value.format"
              :hover="value.unit"
              :badge="value.badge"
              :badge_color="value.badge_color"
              :help="value.help"
              :help_title="value.help_title"
            />
          </b-col>
        </b-row>
        <b-row v-else-if="items[3].badge_color != 'secondary'">
          <b-col cols="12" class="text-left">
            <b-alert
              show
              v-if="more_items.method == 'Early Estimate'"
              variant="info"
              style="font-size: 10pt; color: #6c757d;"
            >
              <span class="font-weight-bold">
                Innovation expenditure - Early Estimate
              </span>
              <br />
              <small>
                This method is used where job occupations are not specified, and
                is determined by the innovation level input by the user. The
                following formula is used:
              </small>
            </b-alert>
            <b-alert
              show
              v-if="more_items.method == 'Standard'"
              variant="info"
              style="font-size: 10pt; color: #6c757d;"
            >
              <span class="font-weight-bold">
                Innovation expenditure - Detailed Information
              </span>
              <br />
              <small>
                This method is used where job occupations are specified. The
                following formula is used:
              </small>
            </b-alert>
            <b-alert
              show
              v-if="more_items.method == 'Capital Intensive'"
              variant="info"
              style="font-size: 10pt; color: #6c757d;"
            >
              <span class="font-weight-bold">
                Innovation expenditure - Capital Intensive
              </span>
              <br />
              <small>
                This method is used where job occupations are specified, however
                jobs are less than or equal to 100 jobs and capital expenditure
                is 90% or more of the total expenditure.
              </small>
            </b-alert>
            <b-alert
              show
              v-if="more_items.method == 'Comprehensive'"
              variant="info"
              style="font-size: 10pt; color: #6c757d;"
            >
              <span class="font-weight-bold">
                Innovation expenditure - Comprehensive Information
              </span>
              <br />
              <small>
                This method is used where job occupations and innovative
                capital/operational expenditure are both specified. The
                following formula is used:
              </small>
            </b-alert>
          </b-col>

          <br />
          <br />
          <b-col class="item" v-if="more_items.method != 'Early Estimate'">
            <SummaryMeasureItem
              measure="R&D and Technical Jobs Wage Expenditure"
              :value="more_items.tech_wage_exp"
              format="$0,0"
            />
          </b-col>
          <b-card-title v-if="more_items.method != 'Early Estimate'"
            >+
          </b-card-title>
          <b-col class="item" v-if="more_items.method != 'Early Estimate'">
            <SummaryMeasureItem
              measure="% of Non R&D and Technical Jobs Wage Expenditure"
              :value="more_items.other_wage_exp * more_items.skill_share"
              format="$0,0"
              hover="% Determined by technical skills proportion"
              :help="
                `${format(more_items.other_wage_exp, '$0,0')} * ${format(
                  more_items.skill_share,
                  '0.[0]%'
                )}`
              "
              help_title="Further Breakdown"
            />
          </b-col>
          <b-col class="item" v-if="more_items.method == 'Early Estimate'">
            <SummaryMeasureItem
              measure="% of Wage Expenditure"
              :value="more_items.wage_exp * more_items.WageEx_share"
              format="$0,0"
              hover="% Determined by innovation level"
              :help="
                `${format(more_items.wage_exp, '$0,0')} * ${format(
                  more_items.WageEx_share,
                  '0.[0]%'
                )} <br/> <i>${more_items.RnD}</i>`
              "
              help_title="Further Breakdown"
            />
          </b-col>
          <b-card-title>+ </b-card-title>

          <b-col
            class="item"
            v-if="
              ['Capital Intensive', 'Early Estimate'].includes(
                more_items.method
              ) && more_items.innovative_exp_known == 'Uncertain'
            "
          >
            <SummaryMeasureItem
              measure="% of Capital Expenditure"
              :value="more_items.total_CapEx * more_items.CapEx_share"
              format="$0,0"
              hover="% Determined by innovation level"
              :help="
                `${format(more_items.total_CapEx, '$0,0')} * ${format(
                  more_items.CapEx_share,
                  '0.[0]%'
                )} <br/> <i>${more_items.RnD}</i>`
              "
              help_title="Further Breakdown"
            />
          </b-col>
          <b-col class="item" v-else-if="more_items.innovative_CapEx != null">
            <SummaryMeasureItem
              measure="Innovative Capital Expenditure"
              :value="more_items.innovative_CapEx"
              format="$0,0"
            />
          </b-col>
          <b-col class="item" v-else>
            <SummaryMeasureItem
              measure="% of Capital Expenditure"
              :value="more_items.total_CapEx * more_items.skill_share"
              format="$0,0.[00]"
              hover="% Determined by technical skills proportion"
              :help="
                `${format(more_items.total_CapEx, '$0,0')} * ${format(
                  more_items.skill_share,
                  '0.[0]%'
                )}`
              "
              help_title="Further Breakdown"
            />
          </b-col>
          <b-card-title v-if="more_items.innovative_OpEx != null">
            +
          </b-card-title>
          <b-col class="item" v-if="more_items.innovative_OpEx != null">
            <SummaryMeasureItem
              measure="Innovative Operational Expenditure"
              :value="more_items.innovative_OpEx"
              format="$0,0"
            />
          </b-col>
        </b-row>
      </b-collapse>
      <!-- <hr v-if="even_more_items != null" />
      <b-row>
        <b-col class="item" v-for="value in even_more_items" :key="value.measure">
          <SummaryMeasureItem
            :measure="value.measure"
            :value="value.value"
            type="currency"
            :hover="value.unit"
            :help="value.help"
          />
        </b-col>
      </b-row> -->
    </b-card>
  </div>
</template>

<script>
var numeral = require("numeral");
import SummaryMeasureItem from "@/components/SummaryMeasureItem.vue";

export default {
  name: "SummaryMeasure",
  components: {
    SummaryMeasureItem,
  },
  props: ["items", "more_items", "even_more_items", "header", "subheader"],
  data() {
    return {
      collapse_visible: true,
    };
  },
  methods: {
    format(value, format) {
      return numeral(value).format(format);
    },
  },
};
</script>

<style scoped lang="scss">
.item {
  padding-left: 0.25em;
  padding-right: 0.25em;
  padding-bottom: 0.5em;
}

@media print {
  .card-body {
    padding-top: 0.5em;
    padding-bottom: 0.25em;
  }
  hr {
    margin-top: 0em;
    margin-bottom: 0.5em;
  }
  .card-title {
    font-size: 1.2em;
  }
  .card-header {
    padding-bottom: 0.2em;
  }
}
</style>
